import React from 'react'
import { getVrFromKeyword, getInputTypeFromVr, InputType } from '../../../lib/deidentification/vr'
import TextInput from './TextInput'
import DateInput from './DateInput'
import SelectInput from '../../Shared/input/SelectInput'
import { codeStringMaps } from '../../../lib/deidentification/codeStringMaps'
import NumberInput from './NumberInput'
import { formatDate } from '../../../lib/format'

type InputComponentProps = {
    dicomKeyword: string
    setNewDicomTag: (value: string) => void
    fixed: boolean,
    defaultValue?: string
}

const InputComponent = ({ dicomKeyword, setNewDicomTag, defaultValue, fixed }: InputComponentProps) => {
    const vr = getVrFromKeyword(dicomKeyword)
    const inputType = getInputTypeFromVr(vr)

    if (fixed) {
        // Format fixed DATE_PICKER, SELECT, NUMBER, PERSON_NAME for default values, as these won't be driven by input components.

        let formattedFixedValue = defaultValue;

        if (inputType === InputType.DATE_PICKER) {
            if (defaultValue) {
                formattedFixedValue = formatDate(defaultValue)
            }
        } else if (inputType === InputType.SELECT) {
            const codeStringMapForKeyword = codeStringMaps[dicomKeyword]

            if (defaultValue) {
                formattedFixedValue = codeStringMapForKeyword.humanReadable[defaultValue]
            }
        } else if (inputType === InputType.PERSON_NAME) {
            formattedFixedValue = formattedFixedValue?.replaceAll('^', ' ')
        }

        return (
            <td className='anonymization-table-cell anonymization-table-cell-fixed'>{formattedFixedValue}</td>
        )
    }

    if (inputType === InputType.DATE_PICKER) {
        const defaultDate = defaultValue && defaultValue.length ? defaultValue : undefined

        return (
            <td className='anonymization-table-input-cell'>
                <DateInput defaultValue={defaultDate} setNewDicomTag={setNewDicomTag} />
            </td>
        )
    }

    if (inputType === InputType.SELECT) {
        const codeStringMapForKeyword = codeStringMaps[dicomKeyword]

        const optionsMap = codeStringMapForKeyword.humanReadable

        const options = Object.keys(optionsMap).map((value) => ({ value, label: optionsMap[value] }))
        const defaultOption = defaultValue ? { value: defaultValue, label: optionsMap[defaultValue] } : undefined

        return (
            <td className='anonymization-table-input-cell'>
                <SelectInput
                    placeholder='Select Value...'
                    options={options}
                    defaultOption={defaultOption}
                    onChange={setNewDicomTag}
                />
            </td>
        )
    }

    if (inputType === InputType.NUMBER) {
        return (
            <td className='anonymization-table-input-cell'>
                <NumberInput defaultValue={defaultValue} setNewDicomTag={setNewDicomTag} />
            </td>
        )
    }


    if (inputType === InputType.PERSON_NAME) {
        // In the case of a non fixed person name default value, replace the carets ("^") with spaces for human input.
        return (
            <td className='anonymization-table-input-cell'>
                <TextInput defaultValue={defaultValue?.replaceAll('^', ' ')} setNewDicomTag={setNewDicomTag} />
            </td>
        )
    }

    return (
        <td className='anonymization-table-input-cell'>
            <TextInput defaultValue={defaultValue} setNewDicomTag={setNewDicomTag} />
        </td>
    )
}

export default InputComponent