import { UploadInput } from "../../../providers/FileStateProvider";
import { fetchSubjectIdAvailableGuest, fetchSubjectIdAvailableLoggedIn } from "../../../lib/api/wms";

const subjectIdToUse = (
    defaultDeidentifiedSubjectId: string | undefined,
    userDefinedDeidentifiedSubjectId: string | undefined
): string | undefined => {
    if (userDefinedDeidentifiedSubjectId !== undefined && userDefinedDeidentifiedSubjectId.length) {
        return userDefinedDeidentifiedSubjectId;
    }

    if (defaultDeidentifiedSubjectId !== undefined && defaultDeidentifiedSubjectId.length) {
        return defaultDeidentifiedSubjectId;
    }
}

const verifyDeidentifiedOrBlindedTrialLevelUpload = async (
    siteId: string,
    trialId: string,
    originalMedicalRecordNumber: string,
    defaultDeidentifiedSubjectId: string | undefined,
    userDefinedDeidentifiedSubjectId: string | undefined,
    guestUploader?: UploadInput['guestUploader']
): Promise<{ canUpload: boolean, subjectIdUnavailable: boolean }> => {
    // Verify subject Id.
    const subjectId = subjectIdToUse(defaultDeidentifiedSubjectId, userDefinedDeidentifiedSubjectId);

    if (!subjectId) {
        return { canUpload: false, subjectIdUnavailable: false };
    }

    // If its the default, we don't need to check if its available since its mandated.
    if (defaultDeidentifiedSubjectId !== undefined && defaultDeidentifiedSubjectId.length) {
        return { canUpload: true, subjectIdUnavailable: false };
    }

    // In the guest uploader case we let them put whatever they want and it will be reconcilled on the backend if the patient link is already in place.
    // But prevent them putting a different subjectId which is certainly already in use.
    if (guestUploader) {
        const subjectIdAvailable = await fetchSubjectIdAvailableGuest(originalMedicalRecordNumber, siteId, trialId, subjectId, guestUploader.guestUploaderId)

        if (subjectIdAvailable) {
            return { canUpload: true, subjectIdUnavailable: false };
        }

        return { canUpload: false, subjectIdUnavailable: true };
    }

    const subjectIdAvailable = await fetchSubjectIdAvailableLoggedIn(siteId, trialId, subjectId);

    if (subjectIdAvailable) {
        return { canUpload: true, subjectIdUnavailable: false };
    }

    return { canUpload: false, subjectIdUnavailable: true };
};

export { verifyDeidentifiedOrBlindedTrialLevelUpload };