import React from 'react';
import logo from '../images/yunu-logomark-gradient.svg'
import './YunuHeader.css';


function YunuHeader() {
    return (
        <div
            className='yunu-header'
        >
            <img src={logo} alt="Yunu" width='100px'></img>
        </div>
    )
}

export default YunuHeader