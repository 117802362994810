
/**
 * Formats a YYYYMMDD string into a displayable date.
 */
const formatDate = (daString: string): string => {
    const year = Number(daString.slice(0,4))
    const month = Number(daString.slice(4,6))
    const day = Number(daString.slice(6,8))

    return `${month}/${day}/${year}`
}

/**
 * Note this works only for DA vr, we do not consider DT yet,
 * but we also do not include any DT tags in our allowlist for now.
 */
const formatDicomDateToWMSDate = (
    dicomDate: string //YYYYMMDD
): string => {
    const year = dicomDate.slice(0, 4);
    const month = dicomDate.slice(4, 6);
    const day = dicomDate.slice(6, 8);

    return `${year}-${month}-${day}T00:00:00.000Z`;
};

export { formatDate, formatDicomDateToWMSDate }