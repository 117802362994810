import React from 'react'
import "./TextInput.css"
import { getFilteredAllowListOptions } from '../../../lib/deidentification/allowedDicomDictionary'
import SelectInput from '../../Shared/input/SelectInput'
import "./NewCustomRow.css"

type NewCustomRowProps = {
    determineRow: (keyword: string) => void
    tagsToOmit: string[]
}

const NewCustomRow = ({determineRow, tagsToOmit}: NewCustomRowProps) => {
    const options = getFilteredAllowListOptions(tagsToOmit)

    return (
        <tr>
            <td colSpan={3} className='anonymization-table-tag-select-cell'>
                <SelectInput options={options} onChange={determineRow}/>
            </td>
        </tr>)
}

export default NewCustomRow