import React from "react";
import { DicomTagOverridesPerStudy, useFileState } from "../../providers/FileStateProvider";
import "../NextPrevButton.css"
import { UserDefinedDeidentifyListPerStudy } from "../../lib/deidentification/types";
import LoadingSpinner from "../Shared/LoadingSpinner";

type UserInputFooterDeidentifiedProps = {
    canUpload: boolean;
    removeBurnedInPHI: boolean;
    defaultDeidentifiedSubjectId?: string;
    userDefinedDeidentifiedSubjectId?: string;
    newDicomTagOverridesPerStudy: DicomTagOverridesPerStudy;
    newUserDefinedDeidentifyListPerStudy: UserDefinedDeidentifyListPerStudy;
    selectedSiteId?: string;
}

function UserInputFooterDeidentified({
    canUpload,
    removeBurnedInPHI,
    defaultDeidentifiedSubjectId,
    userDefinedDeidentifiedSubjectId,
    newDicomTagOverridesPerStudy,
    newUserDefinedDeidentifyListPerStudy,
    selectedSiteId
}: UserInputFooterDeidentifiedProps) {
    const {
        api,
        files,
        attachments,
        studyDateOverride,
        mandatoryRedactBurnedInPHI,
        uploadInput,
        studyMetadata,
        mandatoryDeidentifyList,
        guestSenderInfo,
        status,
        onlyIncludeOriginalImages
    } = useFileState()

    const canUploadAndAnyCancelledUploadsFinished = canUpload && !status.isUploading;
    const uploadButtonClassName = canUploadAndAnyCancelledUploadsFinished ? "next-prev-button" : "next-prev-button next-prev-button_disabled";

    return (
        <div>
            <button className="next-prev-button" onClick={() => {
                api.resetState()
            }}>
                Back
            </button>
            <button className={uploadButtonClassName} disabled={!canUploadAndAnyCancelledUploadsFinished} onClick={() => {
                api.mutateDicomTagOverridesPerStudy(newDicomTagOverridesPerStudy)
                api.mutateUserDefinedDeidentifyList(newUserDefinedDeidentifyListPerStudy)
                api.setManualRedactBurnedInPHI(removeBurnedInPHI)

                const redactBurnedInPHI = mandatoryRedactBurnedInPHI ? true : removeBurnedInPHI

                const subjectId = defaultDeidentifiedSubjectId !== undefined ? defaultDeidentifiedSubjectId : userDefinedDeidentifiedSubjectId;

                api.upload({
                    files,
                    attachments,
                    uploadInput,
                    selectedSiteId,
                    studyMetadata,
                    studyDateOverride,
                    subjectId,
                    deidentification: {
                        mandatoryDeidentifyList,
                        userDefinedDeidentifyListPerStudy:
                            newUserDefinedDeidentifyListPerStudy,
                        dicomTagOverridesPerStudy: newDicomTagOverridesPerStudy,
                        redactBurnedInPHI,
                    },
                    guestSenderInfo,
                    rewriteUIDs: true,
                    onlyIncludeOriginalImages
                });
            }}>
                Upload
            </button>
            {status.isUploading ? <div style={{ padding: '10px' }}><LoadingSpinner /></div> : null}
        </div>
    )
}

export default UserInputFooterDeidentified