// @ts-ignore We don't have types for this library.
import startCase from 'lodash.startcase'
import { formatDate } from '../format';
import { codeStringMaps } from './codeStringMaps'
import { getVrFromKeyword } from './vr';

const UNKNOWN = ''

/**
 * Note any sucks here, but we'll have to basically type a _lot_ of dcmjs
 * to get around it, so using JS for now.
 */
type Tag = any


const parsePNDisplayValue = (tag: Tag): string => {
    if (tag === undefined) {
        return UNKNOWN
    }

    const tagAsArray = tag instanceof Array ? tag : [tag]

    if (tagAsArray.length === 0) {
        return UNKNOWN
    }

    const patientName = tagAsArray[0].Alphabetic

    return patientName.replaceAll(`^`, " ")
}

const parseTextDisplayValue = (tag: Tag): string => {
    if (tag === undefined) {
        return UNKNOWN
    }

    if (tag.length === 0) {
        return UNKNOWN
    }

    return tag
}

const parseDADisplayValue = (tag: Tag): string => {
    if (tag === undefined) {
        return UNKNOWN
    }

    if (tag.length === 0) {
        return UNKNOWN
    }

    return formatDate(tag)
}

const parseCSDisplayValue = (keyword: string, tag: Tag): string => {
    if (tag === undefined) {
        return UNKNOWN
    }

    if (tag.length === 0) {
        return UNKNOWN
    }


    if (keyword === 'PatientSex') {
        return codeStringMaps.PatientSex.humanReadable[tag]
    }

    console.error(`Implement CS for ${keyword}`)

    return ''
}


/**
 * Note this is not an extensive list, just what we currently support.
 */
const getDisplayValueFromTag = (keyword: string, tag: Tag): string => {
    const vr = getVrFromKeyword(keyword)

    if (vr === "PN") {
        return parsePNDisplayValue(tag)
    }

    if (["SH", "LO", "ST", "DS", "AS"].includes(vr)) {
        return parseTextDisplayValue(tag)
    }

    if (vr === "DA") {
        return parseDADisplayValue(tag)
    }

    if (vr === "CS") {
        return parseCSDisplayValue(keyword, tag)
    }

    console.error(`Implement VR: ${vr}`)

    return ''

    // throw new Error (`Implement VR: ${vr}`)
}

const parsePNOriginalValue = (tag: Tag): string | undefined => {
    if (tag === undefined) {
        return
    }

    const tagAsArray = tag instanceof Array ? tag : [tag]

    if (tagAsArray.length === 0) {
        return
    }

    const patientName = tagAsArray[0].Alphabetic

    return patientName;
}

const parseTextOriginalValue = (tag: Tag): string | undefined => {
    if (tag === undefined) {
        return
    }

    if (tag.length === 0) {
        return
    }

    return tag
}

const parseDAOriginalValue = (tag: Tag): string | undefined => {
    if (tag === undefined) {
        return
    }

    if (tag.length === 0) {
        return
    }

    return tag
}

const parseCSOriginalValue = (keyword: string, tag: Tag): string | undefined => {
    if (tag === undefined) {
        return
    }

    if (tag.length === 0) {
        return
    }

    return tag
}

/**
 * Note this is not an extensive list, just what we currently support.
 */
const getOriginalValueFromTag = (keyword: string, tag: Tag): string | undefined => {
    const vr = getVrFromKeyword(keyword)

    if (vr === "PN") {
        return parsePNOriginalValue(tag)
    }

    if (["SH", "LO", "ST", "DS"].includes(vr)) {
        return parseTextOriginalValue(tag)
    }

    if (vr === "DA") {
        return parseDAOriginalValue(tag)
    }

    if (vr === "CS") {
        return parseCSOriginalValue(keyword, tag)
    }

    console.error(`Implement VR: ${vr}`)

    return String(tag)
}

const getDisplayNameFromDicomKeyword = (keyword: string): string => {
    // If we don't have a specific mapping => Return the keywords with spaces added.
    // E.g. QualityControlSubject => Quality Control Subject.
    return startCase(keyword)
}


export {
    getDisplayNameFromDicomKeyword,
    getDisplayValueFromTag,
    getOriginalValueFromTag
}