import { OrderResponse, SitePatientsForPatientResponse, TrialResponse } from "../../../sharedTypes/wmsQueryTypes";
import { getUploadDestinationMessage } from "../lib/getUploadDestinationMessage";

type UploadHeaderProps = {
    trialId?: string,
    trial?: TrialResponse,
    patient?: SitePatientsForPatientResponse,
    order?: OrderResponse,
    isNonTemporaryGuestUploader?: boolean
}

const UploadHeader = ({ trialId, trial, patient, order, isNonTemporaryGuestUploader }: UploadHeaderProps) => {
    const uploadDestinationMessage = getUploadDestinationMessage(trialId, trial, patient, order, isNonTemporaryGuestUploader)

    return (
        <div>
            <h1>Yunu Uploader</h1>
            <h2>{uploadDestinationMessage}</h2>
        </div>
    );
}

export default UploadHeader