import axios from 'axios'

import { getAuth0Token } from '../providers/AuthProvider';


// Axios instance whereby the AuthProvider provides a token.
const auth0Axios = axios.create();

auth0Axios.interceptors.request.use(function (config) {
    const token = getAuth0Token()

    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
})

export{ auth0Axios }