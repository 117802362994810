import React from 'react'
import Select from 'react-select'

type Option = {
    value: string,
    label: string,
    isDisabled?: boolean
}

type SelectInputProps = {
    placeholder?: string,
    selectedOption?: Option
    options: Option[],
    onChange: (option: any) => void
}

const StyledReactSelect = ({ placeholder, selectedOption, onChange, options }: SelectInputProps) => {
    return (
        <Select
          value={selectedOption || null}
          onChange={onChange}
          // @ts-ignore This lib is typed wrong
          options={options}
          placeholder={placeholder}
          styles={{
            // @ts-ignore
            placeholder: (baseStyles) => {
                return {
                    ...baseStyles,
                    color: 'var(--text-green)',
                    fontWeight: "1000"
                }
            },
            // @ts-ignore
            control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? 'var(--input-selected)' : 'var(--input-deselected)',
                // This line disables the default blue input border
                boxShadow: 'none',
                '&:hover': {
                    border: '1px solid var(--input-selected)',
                },
                backgroundColor: 'black',
                minHeight: '26px',
                height: '26px',
            }),
            // @ts-ignore
            option: (baseStyles, { isFocused, isDisabled }) => ({
                ...baseStyles,
                backgroundColor: isFocused && !isDisabled ? "var(--background-grey-blue-dark)" : "var(--background-grey-dark)",
                color: isDisabled ? 'var(--text-disabled)' : 'var(--text-primary)',
            }),
            // @ts-ignore
            menu: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: "var(--background-grey-dark)",
                color: 'var(--text-primary)',
                borderStyle: '1px solid var(--table-grey)'
            }),
            // @ts-ignore
            valueContainer: (baseStyles) => ({
                ...baseStyles,
                height: '26px',
                padding: '0 6px'
            }),
            // @ts-ignore
            singleValue: (baseStyles) => ({
                ...baseStyles,
                color: 'var(--text-primary)'
            }),
            // @ts-ignore
            input: (baseStyles) => ({
                ...baseStyles,
                color: 'var(--text-secondary)',
                margin: '0px'
            }),
            indicatorSeparator: () => ({
                display: 'none',
            }),
            // @ts-ignore
            indicatorsContainer: (baseStyles) => ({
                ...baseStyles,
                height: '26px',
            }),
          }}
        />
      );
}

export default StyledReactSelect
export type { Option }