/**
 * In this application, within a single session, only one upload can be active at a time.
 * During upload we perform many asynchronous processes, which upon cancellation, should terminate immediately/
 * not update state. We do not want to rely on react state updates for this, so this is just global synchronous POJO state for the current uploadInstanceId.
 */

let CURRENT_UPLOADER_INSTANCE_UID = '';

const isCurrentUploaderInstanceId = (uploaderInstanceUid: string): boolean => uploaderInstanceUid === CURRENT_UPLOADER_INSTANCE_UID;
const setCurrentUploaderInstanceId = (uploaderInstanceUid: string): void => { CURRENT_UPLOADER_INSTANCE_UID = uploaderInstanceUid };
const clearCurrentUploaderInstanceId = (): void => { CURRENT_UPLOADER_INSTANCE_UID = '' };

export {
    isCurrentUploaderInstanceId,
    setCurrentUploaderInstanceId,
    clearCurrentUploaderInstanceId
};