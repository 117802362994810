import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Anonymization from '../Anonymization/Anonymization';
import { DicomTagOverridesPerStudy, UnionDeidentificationConfig, UserDefinedDeidentifyListPerStudy } from '../../lib/deidentification/types';

type AnonymizationWithDropDownProps = {
    subjectIdUnavailable: boolean;
    isGuestUploader: boolean;
    setNewDicomTagOverridesPerStudy: React.Dispatch<React.SetStateAction<DicomTagOverridesPerStudy>>;
    setNewUserDefinedDeidentifyListPerStudy: React.Dispatch<React.SetStateAction<UserDefinedDeidentifyListPerStudy>>
    newUserDefinedDeidentifyListPerStudy: UserDefinedDeidentifyListPerStudy;
    mandatoryRedactBurnedInPHI: boolean;
    mandatoryDeidentifyList?: UnionDeidentificationConfig;
    removeBurnedInPHI: boolean;
    setRemoveBurnedInPHI: (value: boolean) => void;
    defaultDeidentifiedSubjectId?: string;
    setNewUserDefinedDeidentifiedSubjectId?: (subjectId: string) => void
}


const AnonymizationWithDropDown = (props: AnonymizationWithDropDownProps) => {
    const {
        setNewDicomTagOverridesPerStudy,
        setNewUserDefinedDeidentifyListPerStudy
    } = props;
    const [showDeidentification, setShowDeidentification] = useState<boolean>(true)


    const setNewDicomTagOverrideForStudy = (StudyInstanceUID: string, keyword: string, value: string) => {
        setNewDicomTagOverridesPerStudy((oldDicomOverridesPerStudy) => {
            const newTagsPerStudy = { ...oldDicomOverridesPerStudy }

            if (newTagsPerStudy[StudyInstanceUID] === undefined) {
                newTagsPerStudy[StudyInstanceUID] = {}
            }

            newTagsPerStudy[StudyInstanceUID][keyword] = value

            return newTagsPerStudy
        })
    }

    const addNewUserDefinedRowForStudy = (StudyInstanceUID: string, keyword: string) => {
        setNewUserDefinedDeidentifyListPerStudy((oldUserDefinedDeidentifyListPerStudy) => {
            const newUserDefinedListPerStudy = { ...oldUserDefinedDeidentifyListPerStudy }

            if (newUserDefinedListPerStudy[StudyInstanceUID] === undefined) {
                newUserDefinedListPerStudy[StudyInstanceUID] = []
            }

            newUserDefinedListPerStudy[StudyInstanceUID] = [...newUserDefinedListPerStudy[StudyInstanceUID], keyword]

            return newUserDefinedListPerStudy
        })
    }

    const deleteUserDefinedRowForStudy = (StudyInstanceUID: string, keyword: string) => {
        setNewUserDefinedDeidentifyListPerStudy((oldUserDefinedDeidentifyListPerStudy) => {
            const newUserDefinedListPerStudy = { ...oldUserDefinedDeidentifyListPerStudy }

            if (newUserDefinedListPerStudy[StudyInstanceUID] !== undefined) {
                const filteredKeywords = newUserDefinedListPerStudy[StudyInstanceUID].filter(kw => kw !== keyword)
                newUserDefinedListPerStudy[StudyInstanceUID] = filteredKeywords
            }

            return newUserDefinedListPerStudy
        })
    }

    return (
        <div>
            <div className="user-input-dropdown">
                <FontAwesomeIcon
                    className="user-input-dropdown-chevron"
                    icon={showDeidentification ? faChevronDown : faChevronRight}
                    onClick={() => setShowDeidentification(!showDeidentification)}
                />
                <h3>Anonymization</h3>
            </div>
            <Anonymization
                show={showDeidentification}
                isGuestUploader={props.isGuestUploader}
                subjectIdUnavailable={props.subjectIdUnavailable}
                setNewDicomTagOverrideForStudy={setNewDicomTagOverrideForStudy}
                addNewUserDefinedRowForStudy={addNewUserDefinedRowForStudy}
                deleteUserDefinedRowForStudy={deleteUserDefinedRowForStudy}
                newUserDefinedDeidentifyListPerStudy={props.newUserDefinedDeidentifyListPerStudy}
                mandatoryRedactBurnedInPHI={props.mandatoryRedactBurnedInPHI}
                mandatoryDeidentifyList={props.mandatoryDeidentifyList}
                removeBurnedInPHI={props.removeBurnedInPHI}
                setRemoveBurnedInPHI={props.setRemoveBurnedInPHI}
                defaultDeidentifiedSubjectId={props.defaultDeidentifiedSubjectId}
                setNewUserDefinedDeidentifiedSubjectId={props.setNewUserDefinedDeidentifiedSubjectId}
            />
        </div>
    );
}

export default AnonymizationWithDropDown;