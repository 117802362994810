import { ByteStream, littleEndianByteArrayParser } from "dicom-parser"
// @ts-ignore We don't have types for pako
import pako from "pako";
// @ts-ignore We don't have types for dcmjs
import dcmjs from 'dcmjs'

// @ts-ignore - We need to add this to the window for dicomParser to be able to parse deflated syntax.
window.pako = pako;

type NaturalizedHeader = any;

function readPrefix(byteArray: any) {
  // @ts-ignore typed wrong
  const littleEndianByteStream = new ByteStream(littleEndianByteArrayParser, byteArray);
  // @ts-ignore typed wrong
  if (littleEndianByteStream.getSize() <= 132) {
    return false;
  }
  littleEndianByteStream.seek(128);
  const prefix = littleEndianByteStream.readFixedString(4);

  return prefix === 'DICM';
}

/**
 * Range reads to only pull the first few bytes of a dicom file to disk,
 * in order to check if they are valid dicom files.
 */
const isDicom = async (file: File): Promise<boolean> => {
  // Read only the first mandatory fields in the header:
  // === 394 bytes
  return new Promise((resolve) => {
    const fileReader = new FileReader()

    if (file.size < 394) {
      // Doesn't even contain P10 header.
      resolve(false)
      return;
    }

    fileReader.onload = (evt: ProgressEvent<FileReader>): void => {
      const data = evt.target?.result

      if (!(data instanceof ArrayBuffer)) {
        resolve(false)
        return;
      }

      const uInt8View = new Uint8Array(data as ArrayBuffer)

      try {
        const isDicom = readPrefix(uInt8View);

        resolve(isDicom)
      } catch (e) {
        resolve(false)
      }
    }

    fileReader.onerror = (): void => {
      resolve(false)
    }

    const fileHeadersOnly = file.slice(0, 394) // TODO work out this number

    fileReader.readAsArrayBuffer(fileHeadersOnly)
  })
}


/**
 * Gets the naturalized DICOM header for a file.
 */
const getDicomHeader = async (file: File): Promise<NaturalizedHeader> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.onload = (evt: ProgressEvent<FileReader>): void => {
      const data = evt.target?.result


      if (!(data instanceof ArrayBuffer)) {
        reject('unparsable data')
      }

      const dicomData = dcmjs.data.DicomMessage.readFile(
        data,
        {
          untilTag: "7FE00010",
          includeUntilTagValue: false
        }
      );

      let naturalizedDataset = dcmjs.data.DicomMetaDictionary.naturalizeDataset(
        dicomData.dict
      );

      naturalizedDataset._meta = dcmjs.data.DicomMetaDictionary.namifyDataset(
        dicomData.meta
      );

      resolve(naturalizedDataset)
    }

    fileReader.readAsArrayBuffer(file)
  })
}

export { isDicom, getDicomHeader }
export type { NaturalizedHeader }