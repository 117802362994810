import React, { useState, useEffect, useMemo } from 'react'
import { StudyMetadata } from '../../providers/FileStateProvider'
import { formatDate } from '../../lib/format'
import AnonymizationTableRow from './AnonymizationTableRow'
import { getDisplayValueFromTag, getOriginalValueFromTag } from '../../lib/deidentification/getTagsForDisplay'
import './AnonymizationTable.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import NewCustomRow from './input/NewCustomRow'
import { ALLOWED_TAG_COUNT } from '../../lib/deidentification/allowedDicomDictionary'
import { UnionDeidentificationConfig } from '../../lib/deidentification/types'

type AnonymizationTableProps = {
    studyMetadata: StudyMetadata,
    mandatoryDeidentifyList?: UnionDeidentificationConfig,
    userDefinedDeidentifyList?: string[],
    dicomTagOverrides?: Record<string, string>
    setNewDicomTagOverride: (keyword: string, value: string) => void
    addNewUserDefinedRow: (keyword: string) => void
    deleteUserDefinedRow: (keyword: string) => void
}

const getDefaultValue = (overrideValue: string | undefined, defaultValue: string | undefined, dicomKeyword: string, dicomTag: any): string | undefined => {
    if (overrideValue) {
        return overrideValue
    }

    if (defaultValue) {
        return defaultValue
    }

    return getOriginalValueFromTag(dicomKeyword, dicomTag)
}

const getKeywordsInTable = (
    mandatoryDeidentifyList?: UnionDeidentificationConfig,
    userDefinedDeidentifyList?: string[]
): string[] => {

    const mandatoryList = mandatoryDeidentifyList && mandatoryDeidentifyList.map(item => item.tag)

    const keywords: string[] = []

    if (mandatoryList) {
        keywords.push(...mandatoryList)
    }

    if (userDefinedDeidentifyList) {
        keywords.push(...userDefinedDeidentifyList)
    }

    return keywords
}

const AnonymizationTable = ({
    studyMetadata,
    mandatoryDeidentifyList,
    userDefinedDeidentifyList,
    dicomTagOverrides,
    setNewDicomTagOverride,
    addNewUserDefinedRow,
    deleteUserDefinedRow
}: AnonymizationTableProps) => {
    const [emptyRowCount, setEmptyRowCount] = useState<number>(0)
    const [keywordsInTable, setKeywordsInTable] = useState<string[]>([])

    const Header = () => {
        const { header } = studyMetadata

        const date = formatDate(header["StudyDate"])
        const description = header["StudyDescription"]

        // Constuct header based on available information.
        let text = ''

        if (date !== undefined || description !== undefined) {
            // Have at least one, write a header
            text = `Study Information:`

            if (date !== undefined) {
                text += ` ${date}`
            }

            if (description !== undefined) {
                text += ` ${description}`
            }
        }

        return <h2 className='anonymization-table-header'>{text}</h2>
    }

    useEffect(() => {
        setKeywordsInTable(getKeywordsInTable(mandatoryDeidentifyList, userDefinedDeidentifyList))
    }, [mandatoryDeidentifyList, userDefinedDeidentifyList])

    const addRow = () => {
        setEmptyRowCount(oldEmptyRowCount => oldEmptyRowCount + 1)
    }

    const determineRow = ((keyword: string) => {
        addNewUserDefinedRow(keyword)
        setEmptyRowCount(oldEmptyRowCount => oldEmptyRowCount - 1)
    })

    const deleteRow = (keyword: string) => {
        deleteUserDefinedRow(keyword)
    }

    const Rows = useMemo(() => {
        return (
            <React.Fragment>
                {mandatoryDeidentifyList && mandatoryDeidentifyList.map(({ tag, defaultValue, fixed, tooltipMessage }) => {
                    const dicomKeyword = tag
                    const dicomTag = studyMetadata.header[dicomKeyword]
                    const originalDisplayValue = getDisplayValueFromTag(dicomKeyword, dicomTag)
                    const overrideValue = dicomTagOverrides && dicomTagOverrides[dicomKeyword]
                    const defaultValueToUse = getDefaultValue(overrideValue, defaultValue, dicomKeyword, dicomTag)
                    const setNewDicomTag = (value: string) => setNewDicomTagOverride(dicomKeyword, value)

                    return (
                        <AnonymizationTableRow
                            key={dicomKeyword}
                            dicomKeyword={dicomKeyword}
                            originalValue={originalDisplayValue}
                            defaultValue={defaultValueToUse}
                            setNewDicomTag={setNewDicomTag}
                            fixed={!!fixed}
                            tooltipMessage={tooltipMessage}
                        />
                    )
                })}
                {userDefinedDeidentifyList && userDefinedDeidentifyList.map(dicomKeyword => {
                    const dicomTag = studyMetadata.header[dicomKeyword]
                    const originalDisplayValue = getDisplayValueFromTag(dicomKeyword, dicomTag)
                    const overrideValue = dicomTagOverrides && dicomTagOverrides[dicomKeyword]
                    const defaultValueToUse = getDefaultValue(overrideValue, undefined, dicomKeyword, dicomTag)
                    const setNewDicomTag = (value: string) => setNewDicomTagOverride(dicomKeyword, value)

                    return (
                        <AnonymizationTableRow
                            key={dicomKeyword}
                            dicomKeyword={dicomKeyword}
                            originalValue={originalDisplayValue}
                            defaultValue={defaultValueToUse}
                            setNewDicomTag={setNewDicomTag}
                            fixed={false}
                            deleteRow={deleteRow}
                        />
                    )
                })}
            </React.Fragment>
        )
    }, [dicomTagOverrides, studyMetadata.StudyInstanceUID, studyMetadata.header, mandatoryDeidentifyList, userDefinedDeidentifyList])


    return (
        <div style={{ display: 'flex', flexDirection: "column" }}>
            <Header />
            <table className='anonymization-table'>
                <tbody>
                    <tr>
                        <th>Dicom Tag</th>
                        <th>Original Value</th>
                        <th>New Value</th>
                        <th className='anonymization-table-delete-column'></th>
                    </tr>
                    {Rows}
                    {emptyRowCount > 0 ? Array.from({ length: emptyRowCount }).map((it, index) => {
                        return (
                            <NewCustomRow
                                key={index}
                                determineRow={determineRow}
                                tagsToOmit={keywordsInTable}
                            />
                        )
                    }) : null}
                    {/** If the total number of rows + new rows is equal to the total allowed tags, hide the add row button */}
                    {ALLOWED_TAG_COUNT !== keywordsInTable.length + emptyRowCount ? <tr>
                        <td className='anon-and-attachements-new-cell'>
                            <FontAwesomeIcon
                                className="anonymization-table-new-row"
                                icon={faPlus}
                                onClick={addRow}
                            />
                        </td>
                    </tr> : null}
                </tbody>
            </table>
        </div>
    )
}

export default AnonymizationTable