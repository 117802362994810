import { PatientInfo } from "../../../../sharedTypes/wmsQueryTypes";

/**
 * Converts the WMS formatted date to a DICOM date.
 */
const wmsDateToDICOMDate = (wmsDate: string) => {
    const wmsDateMinusTime = wmsDate.split('T')[0];

    const [year, month, day ] = wmsDateMinusTime.split('-');

    return `${year}${month}${day}`
}

const getDicomPatientSexCodeValueFromWMSGender = (wmsGender: PatientInfo['Gender']): "M" | "F" | "O" => {
    if (wmsGender === 'Male') {
        return "M"
    } else if (wmsGender === 'Female') {
        return "F"
    } else {
        return "O"
    }
}

const firstAndLastNameToDICOMPatientName = (firstName: string, lastName: string): string => {
    return `${firstName}^${lastName}`
}

export { wmsDateToDICOMDate, getDicomPatientSexCodeValueFromWMSGender, firstAndLastNameToDICOMPatientName }