import { PatientInfo } from "../../../../sharedTypes/wmsQueryTypes";
import { StudyMetadata } from "../../providers/FileStateProvider";
import { wmsDateToDICOMDate, getDicomPatientSexCodeValueFromWMSGender, firstAndLastNameToDICOMPatientName } from './utils'
import { DICOMFormatPatientInfo, PatientSexValue } from "./types";

const getMrnFromDICOM = (studyMetadata: StudyMetadata[]): DICOMFormatPatientInfo['PatientID'] => {
    const studyHeader = studyMetadata[0].header;

    const PatientIDTag: string | undefined = studyHeader.PatientID.length ? studyHeader.PatientID : undefined;

    return {
        value: PatientIDTag || '',
        locked: PatientIDTag !== undefined
    }
}

/**
 * Make the patient info from the WMS or DICOM into DICOM format.
 * This is for consistency so we don't use two types (WMS-style and DICOM style for patient creation and deidentification).
 * 
 * Note we do however use spaces for names, because we patch carets ("^") in at the end.
 */
const formatWMSPatientInfo = (studyMetadata: StudyMetadata[], patientInfo: PatientInfo): DICOMFormatPatientInfo => {
    const { FirstName, LastName, DateOfBirth, Gender } = patientInfo;
    const PatientID = getMrnFromDICOM(studyMetadata)
    const PatientName = {
        value: firstAndLastNameToDICOMPatientName(FirstName, LastName),
        locked: true
    };
    const PatientBirthDate = {
        value: wmsDateToDICOMDate(DateOfBirth),
        locked: true
    }
    const PatientSex = {
        value: getDicomPatientSexCodeValueFromWMSGender(Gender),
        locked: true
    }

    return {
        PatientID,
        PatientName,
        PatientBirthDate,
        PatientSex
    }
}


const getPatientInfoFromDICOM = (studyMetadata: StudyMetadata[]): DICOMFormatPatientInfo => {
    const studyHeader = studyMetadata[0].header;

    const PatientNameTag: string | undefined = studyHeader.PatientName?.[0]?.Alphabetic;
    const PatientSexTag: PatientSexValue | undefined = studyHeader.PatientSex.length ? studyHeader.PatientSex : undefined;
    const PatientBirthDateTag: string | undefined = studyHeader.PatientBirthDate.length ? studyHeader.PatientBirthDate : undefined;

    const PatientID = getMrnFromDICOM(studyMetadata)
    const PatientName = {
        value: PatientNameTag || '',
        locked: PatientNameTag !== undefined
    }
    const PatientSex = {
        value: PatientSexTag || 'O',
        locked: PatientSexTag !== undefined
    }
    const PatientBirthDate = {
        value: PatientBirthDateTag || '',
        locked: PatientBirthDateTag !== undefined
    }

    return {
        PatientID,
        PatientName,
        PatientSex,
        PatientBirthDate
    }
}

const getDICOMFormattedPatientInfo = (studyMetadata: StudyMetadata[], patientInfoFromWMS?: PatientInfo): DICOMFormatPatientInfo => {
    if (patientInfoFromWMS) {
        return formatWMSPatientInfo(studyMetadata, patientInfoFromWMS);
    }

    return getPatientInfoFromDICOM(studyMetadata);
}

export { getDICOMFormattedPatientInfo };
export type { DICOMFormatPatientInfo };