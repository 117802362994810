import { useAuth0 } from '@auth0/auth0-react';

/**
 * Logs out of Auth0 and navigates back to the Auth0 login page.
 * Implemented as a route so that other apps can easily call it.
 * 
 * As site level/trial level etc uploaders are almost like seperate apps, we redirect back to the same page.
 * For example it would be weird to be logged out an order level uploader and then log back in to the site level uploader (root).
 */
const Auth0Logout = () => {
    const { logout } = useAuth0();

    let loginRedirect = localStorage.getItem('loginRedirect')
    localStorage.removeItem('loginRedirect')

    if (loginRedirect === null) {
        // Fallback to root.
        loginRedirect = window.location.origin;
    }

    logout({ logoutParams: { returnTo: loginRedirect } });

    return null;
}

export default Auth0Logout;