import { getAuth0Token } from '../../providers/AuthProvider';

const PERMISSIONS = 'https://yunu.io/permissions'

const canUploadToOrganizationLevel = (): boolean => {
    const auth0Token = getAuth0Token();
    const arrayToken = auth0Token.split('.');
    const tokenPayload = JSON.parse(atob(arrayToken[1]));
    const permissions = tokenPayload[PERMISSIONS];

    return permissions.includes("DataUpload.OrganizationLevel");
};


export {
    canUploadToOrganizationLevel
}