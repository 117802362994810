import React from "react";
import { UploadLevel, useFileState } from "../../providers/FileStateProvider";
import "../NextPrevButton.css"
import { SitePatientInfo } from "../../../../sharedTypes/wmsQueryTypes";
import { generateWMSPatientInformation } from "../../lib/patientInformation/combinePatientInformation";
import { DICOMFormatPatientInfo } from "../../lib/patientInformation/getDICOMFormattedPatientInfo";
import LoadingSpinner from "../Shared/LoadingSpinner";


type UserInputFooterIdentifiedProps = {
    canUpload: boolean;
    defaultPatientInfo?: DICOMFormatPatientInfo;
    userDefinedPatientInfo: Partial<DICOMFormatPatientInfo>;
    selectedSiteId?: string;
}


function UserInputFooterIdentified({
    canUpload,
    defaultPatientInfo,
    userDefinedPatientInfo,
    selectedSiteId
}: UserInputFooterIdentifiedProps) {
    const {
        api,
        files,
        attachments,
        studyDateOverride,
        uploadInput,
        studyMetadata,
        guestSenderInfo,
        rewriteUIDs,
        status,
        onlyIncludeOriginalImages
    } = useFileState()

    const canUploadAndAnyCancelledUploadsFinished = canUpload && !status.isUploading;
    const uploadButtonClassName = canUploadAndAnyCancelledUploadsFinished ? "next-prev-button" : "next-prev-button next-prev-button_disabled";

    // TODO_JAMES => Refactor next-prev-button into a component with props title and disabled.
    return (
        <div>
            <button className="next-prev-button" onClick={() => {
                api.resetState()
            }}>
                Back
            </button>
            <button className={uploadButtonClassName} disabled={!canUploadAndAnyCancelledUploadsFinished} onClick={() => {
                let patientInformation: SitePatientInfo | undefined = undefined;

                if (uploadInput.uploadLevel === UploadLevel.TRIAL) {
                    patientInformation = generateWMSPatientInformation(defaultPatientInfo, userDefinedPatientInfo);
                }

                api.upload({
                    files,
                    attachments,
                    uploadInput,
                    selectedSiteId,
                    studyMetadata,
                    studyDateOverride,
                    patientInformation,
                    guestSenderInfo,
                    rewriteUIDs,
                    onlyIncludeOriginalImages
                });
            }}>
                Upload
            </button>
            {status.isUploading ? <div style={{ padding: '10px' }}><LoadingSpinner /></div> : null}
        </div>
    )
}

export default UserInputFooterIdentified