import React from "react";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import "./PatientInfoInput.css"
import PatientInfoRow from "./PatientInfoRow";

type DeidentifiedPatientInfoArgs = {
  defaultDeidentifiedSubjectId?: string | undefined;
  setNewUserDefinedDeidentifiedSubjectId?: (subjectId: string) => void;
  subjectIdUnavailable: boolean;
  isGuestUploader: boolean;
};

/**
 * When we are logged in, we only ever get subjectIdUnavailable when we
 * know we are uploading data for a new patient.
 */
const subjectIdUnavailableMessageLoggedIn = "Subject ID is unavailable for new patients on this trial.";

/**
 * When we are a guest, we only know that this subjectId isn't available, we do not know this is not a new patient.
 */
const subjectIdUnavailableMessageGuest = "Subject ID is unavailable on this trial.";

const DeidentifiedPatientInfoInput = ({
  defaultDeidentifiedSubjectId,
  setNewUserDefinedDeidentifiedSubjectId,
  subjectIdUnavailable,
  isGuestUploader
}: DeidentifiedPatientInfoArgs) => {
  const isFixed = defaultDeidentifiedSubjectId !== undefined && defaultDeidentifiedSubjectId !== null && !!defaultDeidentifiedSubjectId.length


  /**
   * Note: the LocalizationProvider is required to drive any date picker components in the table hierarchy.
   */
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <h2 className='patient-info-table-header'>Enter Subject ID for Patient</h2>
        <table className='patient-info-table'>
          <tbody>
            <PatientInfoRow
              displayName="Subject ID"
              dicomKeyword={"PatientID"}
              setNewValue={(subjectId: string) => setNewUserDefinedDeidentifiedSubjectId?.(subjectId)}
              fixed={isFixed}
              defaultValue={defaultDeidentifiedSubjectId || ''}
            />
          </tbody>
        </table>
        {subjectIdUnavailable ?
          <p className="subject-id-warning">
            {isGuestUploader ? subjectIdUnavailableMessageGuest : subjectIdUnavailableMessageLoggedIn}
          </p>
          : null
        }
      </div>
    </LocalizationProvider>
  );
}


export default DeidentifiedPatientInfoInput