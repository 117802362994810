import React from 'react'
import "./TextInput.css"

type TextInputProps = {
    defaultValue?: string
    setNewDicomTag: (value: string) => void
}

const TextInput = ({ defaultValue, setNewDicomTag }: TextInputProps) => {
    const onTextChange = (evt: any) => {
        const value = evt.target.value

        setNewDicomTag(value)
    }

    return (
        <input
            className='anonymization-table-text-input'
            type='text'
            placeholder='Enter Value...'
            defaultValue={defaultValue}
            onChange={onTextChange}
        />
    );
}

export default TextInput