import React, { useEffect, useState } from "react";
import SelectDataFooter from "../SelectDataFooter";
import DicomSelector from "../DicomSelector";
import SelectStudies from "../Shared/SelectStudies";
import UploadInstructions from "../Shared/UploadInstructions";

type SiteUploaderSelectDataProps = {
    trial_id?: string,
    uploadInstructions?: string
};

const SiteUploaderSelectData = ({ trial_id, uploadInstructions }: SiteUploaderSelectDataProps) => {
    return (
        <div className="site-uploader-select-data">
            {uploadInstructions ? <UploadInstructions uploadInstructions={uploadInstructions} /> : null}
            <DicomSelector />
            <SelectStudies />
            <SelectDataFooter
                canUploadAttachments={trial_id !== undefined}
            />
        </div>
    );
}


export default SiteUploaderSelectData