import React, { useEffect, useState } from 'react';
import './GuestUploaderManager.css';
import { auth0Axios } from '../../auth/auth0Axios';
import { SITE_URL_LOCAL_STORAGE_KEY } from '../../auth/localStorageKeys';
import { auth0TokenPromise } from '../../providers/AuthProvider';
import { ApiKey, CreateAPIKeyResponse } from '../../../../sharedTypes/bucketUploadTypes';
import './ApiKeyManager.css';

const nonSuperUserMessage = 'Only Super Users can access API Key Management';

/**
 * API Key management. This is only available to Super Users.
 */
function ApiKeyManager() {
    const wmsUrl: string | null = localStorage.getItem(SITE_URL_LOCAL_STORAGE_KEY)

    const [apiKeyList, setApiKeyList] = useState<ApiKey[]>([]);
    const [displayNonSuperUserMessage, setDisplayNonSuperUserMessage] = useState<boolean>(false);

    const [newSecretKey, setNewSecretKey] = useState<string | undefined>(undefined)

    const [newKeyDescription, setNewKeyDescription] = useState('New Key Name');

    const fetchList = async () => {
        try {
            /**
             * Await our token promise we can use API auth Auth0 authentication.
             */
            await auth0TokenPromise;
            const result = await auth0Axios.get('/api/keys/list');

            setApiKeyList(result.data);
        } catch (e) {
            setDisplayNonSuperUserMessage(true);
        }
    };

    const onKeyDescriptionInput = (evt: any) => {
        const value = evt.target.value;

        setNewKeyDescription(value)
    }

    const createAPIKey = async (description: string) => {
        const result = await auth0Axios.post('/api/keys', { description });

        const data: CreateAPIKeyResponse = result.data;

        const { apiKey, secretToken } = data;

        setApiKeyList((oldList) => {
            return [...oldList, apiKey]
        })
        setNewSecretKey(secretToken);
    }

    const deleteAPIKey = async (id: number) => {
        await auth0Axios.delete(`/api/keys/${id}`);

        setApiKeyList((oldList) => {
            const newList = [...oldList];
            const removedIndex = newList.findIndex(item => item.id === id)

            newList.splice(removedIndex, 1)

            return newList;
        })
        setNewSecretKey(undefined);
    }

    useEffect(() => {
        fetchList();
    }, [wmsUrl])

    if (displayNonSuperUserMessage) {
        return (
            <div className="App">
                <h1>API Key Manager</h1>
                <p>{nonSuperUserMessage}</p>
            </div>
        );
    }

    return (
        <div className="App">
            <h1>API Key Manager</h1>
            <table className='api-key-manager-table'>
                <tbody>
                    <tr className='api-key-manager-table-header'>
                        <th className='api-key-manager-key-column'>Key Name</th>
                        <th>Delete</th>
                    </tr>
                    {apiKeyList.map(apiKeyItem => {
                        return (
                            <tr key={apiKeyItem.id}>
                                <td>{apiKeyItem.description}</td>
                                <td><button className='api-key-manager-button' onClick={() => deleteAPIKey(apiKeyItem.id)}>Delete API Key</button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <input value={newKeyDescription} onInput={onKeyDescriptionInput}></input>
            <button className='api-key-manager-button' onClick={() => createAPIKey(newKeyDescription)}>Create New API Key</button>
            {newSecretKey ? (
                <div>
                    <p>Secret Key (This is irretrievable once you leave the page or perform another action):</p>
                    <p className='api-key-manager-secret-key'>{newSecretKey}</p>
                </div>
            ) : null}
        </div>
    );
}

export default ApiKeyManager;