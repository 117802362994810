import React from 'react';

import "./CheckBox.css";

type CheckBoxProps = {
    title: string;
    value: boolean;
    onToggle: () => void;
    fixedOn?: boolean;
}

const CheckBox = ({ title, value, onToggle, fixedOn }: CheckBoxProps) => {
    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", margin: "auto" }}>
            <label className={fixedOn ? "checkmark-container checkmark-container-disabled" : "checkmark-container"}>
                {fixedOn
                    ? <input type="checkbox" checked={true} readOnly disabled />
                    : <input type="checkbox" checked={value} onChange={onToggle} />}
                <span className={fixedOn ? "checkmark checkmark-disabled" : "checkmark"}></span>
                {title}
            </label>
        </div>
    );
};

export default CheckBox;