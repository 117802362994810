import React from 'react';
import { UploadLevel, useFileState } from '../../providers/FileStateProvider';
import OrganizationLevelInput from './OrganizationLevelInput';
import IdentifiedTrialLevelInput from './IdentifiedTrialLevelInput';
import BlindedTrialLevelInput from './BlindedTrialLevelInput';
import IdentifiedOrderLevelInput from './IdentifiedOrderLevelInput';
import BlindedOrderLevelInput from './BlindedOrderLevelInput';
import DeidentifiedTrialLevelInput from './DeidentifiedTrialLevelInput';
import DeidentifiedOrderLevelInput from './DeidentifiedOrderLevelInput';

type TrialLevelInputProps = {
    isBlindedTrial: boolean,
    deidentifying: boolean,
    trialId: string,
    siteId: string
};

type OrderLevelInputProps = {
    isBlindedTrial: boolean,
    deidentifying: boolean
};


const TrialLevelInput = ({
    isBlindedTrial,
    deidentifying,
    trialId,
    siteId
}: TrialLevelInputProps) => {
    if (deidentifying) {
        return (
            <DeidentifiedTrialLevelInput
                trialId={trialId}
                siteId={siteId}
            />
        );
    }

    if (isBlindedTrial) {
        return (
            <BlindedTrialLevelInput
                trialId={trialId}
                siteId={siteId}
            />
        );
    }

    return (
        <IdentifiedTrialLevelInput
            siteId={siteId}
        />
    );
}

const OrderLevelInput = ({
    isBlindedTrial,
    deidentifying
}: OrderLevelInputProps) => {
    if (deidentifying) {
        return <DeidentifiedOrderLevelInput />;
    }

    if (isBlindedTrial) {
        return <BlindedOrderLevelInput />;
    }

    return <IdentifiedOrderLevelInput />;
}

/**
 * Builds the correct user interface for the level of upload we are doing.
 */
const UserInput = () => {
    const { uploadInput, siteId } = useFileState();
    const { uploadLevel, isBlindedTrial, deidentifying } = uploadInput;

    if (uploadLevel === UploadLevel.ORGANIZATION) {
        return <OrganizationLevelInput />;
    }

    if (uploadLevel === UploadLevel.TRIAL) {
        if (uploadInput.trial_id === undefined) {
            throw new Error('Should have trial_id on trial level upload');
        }

        if (siteId === undefined) {
            throw new Error('Should have siteId on trial level upload');
        }

        return (
            <TrialLevelInput
                isBlindedTrial={isBlindedTrial}
                deidentifying={deidentifying}
                trialId={uploadInput.trial_id}
                siteId={siteId}
            />
        );
    }

    if (uploadLevel === UploadLevel.ORDER) {
        return (
            <OrderLevelInput
                isBlindedTrial={isBlindedTrial}
                deidentifying={deidentifying}
            />
        );
    }

    throw new Error('Unrecognized upload level');
}

export default UserInput;