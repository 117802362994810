

const allowList = [
    "PatientName",
    "PatientSex",
    "PatientBirthDate",
    "AccessionNumber",
    "Manufacturer",
    "InstitutionName",
    "InstitutionAddress",
    "ReferringPhysicianName",
    "ConsultingPhysicianName",
    "StationName",
    "StudyDescription",
    "InstitutionalDepartmentName",
    "PhysiciansOfRecord",
    "PerformingPhysicianName",
    "NameOfPhysiciansReadingStudy",
    "OperatorsName",
    "AdmittingDiagnosesDescription",
    "ManufacturerModelName",
    "DerivationDescription",
    "IssuerOfPatientID",
    "QualityControlSubject",
    "OtherPatientNames",
    "PatientAge",
    "PatientSize",
    "MeasuredAPDimension",
    "MeasuredLateralDimension",
    "MedicalAlerts",
    "Allergies",
    "EthnicGroup",
    "Occupation",
    "SmokingStatus",
    "AdditionalPatientHistory",
    "PregnancyStatus",
    "ResponsiblePerson",
    "ResponsiblePersonRole",
    "ResponsibleOrganization",
    "PatientComments",
    "ClinicalTrialSponsorName",
    "ClinicalTrialProtocolID",
    "ClinicalTrialProtocolName",
    "ClinicalTrialSiteName",
    "ClinicalTrialSubjectReadingID",
    "ClinicalTrialTimePointID",
    "ClinicalTrialTimePointDescription",
    "ClinicalTrialCoordinatingCenterName",
    "ClinicalTrialProtocolEthicsCommitteeName",
    "ClinicalTrialProtocolEthicsCommitteeApprovalNumber",
    "BodyPartExamined",
    "ProtocolName",
    "FilterType",
    "StudyID",
    "RequestingService",
    "ReasonForVisit",
    "AdmissionID",
    "ServiceEpisodeID",
    "ServiceEpisodeDescription",
    "PatientState",
    "PerformedProcedureStepID",
    "PerformedProcedureStepDescription",
    "CommentsOnThePerformedProcedureStep",
    "ContainerIdentifier",
    "ContainerDescription",
    "AcquisitionContextDescription",
]

export { allowList }