import React from "react";
import UserInputFooterIdentified from "./UserInputFooterIdentified";
import RewriteUIDs from "./RewriteUIDs";
import "./UserInput.css"

const OrganizationLevelInput = () => {
    return (
        <div className="user-input-layout">
            <UserInputFooterIdentified
                canUpload={true}
                userDefinedPatientInfo={{}}
            />
        </div>
    );
};

export default OrganizationLevelInput