import React from "react"

import "./SelectDataFooter.css"

const SelectDataFooter = ({canUploadAttachments}: {canUploadAttachments: boolean}) => {
    if (canUploadAttachments) {
        return (
            <p>
                Please select DICOM files only. Attachments in other<br/>
                formats can be added after selecting the DICOM files. If you<br/>
                are having problems uploading files please contact Yunu<br/>
                Support at <span className="highlight-span">support@yunu.io</span>
            </p>
        )
    }

    return (
        <p>
            Please select DICOM files only. If you are having problems<br/>
            uploading files please contact Yunu Support at<br/>
            <span className="highlight-span">support@yunu.io</span>
        </p>
    )
}

export default SelectDataFooter