import { UploadIssues } from "../../../sharedTypes/bucketUploadTypes";

const downloadUploadIssuesCSV = (uploadIssues: UploadIssues): void => {
    const ALREADY_IN_YUNU = "Already in Yunu";
    const FAILED_TO_UPLOAD = "Failed to upload";

    const alreadyPresentRows: string[][] = [];
    const failedRows: string[][] = [];

    Object.keys(uploadIssues.studies).forEach(StudyInstanceUID => {
        const study = uploadIssues.studies[StudyInstanceUID];

        Object.keys(study.series).forEach(SeriesInstanceUID => {
            const series = study.series[SeriesInstanceUID];

            series.alreadyPresentInstances.forEach(SOPInstanceUID => {
                alreadyPresentRows.push([ALREADY_IN_YUNU, StudyInstanceUID, SeriesInstanceUID, SOPInstanceUID]);
            });

            series.failedInstances.forEach(SOPInstanceUID => {
                failedRows.push([FAILED_TO_UPLOAD, StudyInstanceUID, SeriesInstanceUID, SOPInstanceUID]);
            });

        })
    });

    const rows: string[][] = [
        ["issue", "StudyInstanceUID", "SeriesInstanceUID", "SOPInstanceUID"],
        ...alreadyPresentRows,
        ...failedRows
    ];

    let csvContent = "data:text/csv;charset=utf-8,";

    rows.forEach(function (rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
};

export { downloadUploadIssuesCSV };