import React, { useState } from 'react'
import StyledReactSelect from './StyledReactSelect'

type Option = {
    value: string,
    label: string
}

type SelectInputProps = {
    defaultOption?: Option;
    placeholder?: string;
    options: Option[];
    onChange: (value: string) => void;
}

const SelectInput = ({ placeholder, defaultOption, onChange, options }: SelectInputProps) => {
    const [selectedOption, setSelectedOption] = useState(defaultOption ? defaultOption : undefined)

    const onSelectChange = (option: any) => {
        setSelectedOption(option)
        onChange(option.value)
    }

    return (
        <StyledReactSelect
            placeholder={placeholder}
            selectedOption={selectedOption}
            options={options}
            onChange={onSelectChange}
        />
      );
}

export default SelectInput