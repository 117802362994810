import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";

import { useSearchParams } from "react-router-dom";

import AuthWrapper from './AuthWrapper'
import SiteFinder from "../components/SiteFinder";
import { SITE_URL_LOCAL_STORAGE_KEY } from "./localStorageKeys";

export const AuthenticationGuard = ({ component }) => {
  let [searchParams] = useSearchParams();

  let siteUrl = searchParams.get('siteUrl')

  if (siteUrl) {
    localStorage.setItem(SITE_URL_LOCAL_STORAGE_KEY, siteUrl)
  } else {
    siteUrl = localStorage.getItem(SITE_URL_LOCAL_STORAGE_KEY)
  }

  if (!siteUrl) {
    // Note this just displays an error message right now,
    // we may have site selection in the future.
    <SiteFinder/>
  }

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (<div>Redirecting...</div>),
  });

  return <AuthWrapper Component={Component} siteUrl = {siteUrl} />;
};