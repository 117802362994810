import React from 'react';
import { useDropzone } from 'react-dropzone';
import './DropZone.css';

type DropZoneProps = {
    onDrop: (files: File[]) => void;
    disabled?: boolean;
}

const DropZone = ({ onDrop, disabled }: DropZoneProps) => {
    const onDropHandler = (files: File[]): void => {
        if (disabled === true) {
            return;
        }

        onDrop(files);
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: onDropHandler });

    const className = disabled ? 'file-dropzone-disabled' : 'file-dropzone';
    const dropText = disabled ? 'Drag and Drop' : 'Drop files here';

    return (
        < div {...getRootProps()} className={className} >
            <input {...getInputProps()} />
            {isDragActive ? <p>{dropText}</p> : <p>Drag and Drop</p>}
        </div >
    );
}

export default DropZone;