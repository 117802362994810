import React from "react";
import { useFileState } from "../../providers/FileStateProvider";
import AnonymizationTable from "./AnonymizationTable";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { UnionDeidentificationConfig, UserDefinedDeidentifyListPerStudy } from "../../lib/deidentification/types";
import DeidentifiedPatientInfoInput from "../PatientInfoInput/DeidentifiedPatientInfoInput";
import CheckBox from "../Shared/CheckBox";
import "./Anonymization.css"


const disallowedDeidentifyTagsMessage = (disallowedDeidentifyTags: string[]): string => {
  if (disallowedDeidentifyTags.length === 1) {
    return `${disallowedDeidentifyTags[0]} modification is not supported. If this is an important feature to you, please contact support@yunu.io`
  }

  return `Modification of ${disallowedDeidentifyTags.length} tags, including ${disallowedDeidentifyTags[0]} is not supported. If this is an important feature to you, please contact support@yunu.io`
}


type AnonymizationProps = {
  show: boolean;
  isGuestUploader: boolean;
  subjectIdUnavailable: boolean;
  setNewDicomTagOverrideForStudy: (StudyInstanceUID: string, keyword: string, value: string) => void;
  addNewUserDefinedRowForStudy: (StudyInstanceUID: string, keyword: string) => void;
  deleteUserDefinedRowForStudy: (StudyInstanceUID: string, keyword: string) => void;
  newUserDefinedDeidentifyListPerStudy: UserDefinedDeidentifyListPerStudy;
  mandatoryRedactBurnedInPHI: boolean;
  mandatoryDeidentifyList?: UnionDeidentificationConfig;
  removeBurnedInPHI: boolean;
  setRemoveBurnedInPHI: (value: boolean) => void;
  defaultDeidentifiedSubjectId?: string;
  setNewUserDefinedDeidentifiedSubjectId?: (subjectId: string) => void
}

const Anonymization = ({
  show,
  subjectIdUnavailable,
  isGuestUploader,
  setNewDicomTagOverrideForStudy,
  addNewUserDefinedRowForStudy,
  deleteUserDefinedRowForStudy,
  newUserDefinedDeidentifyListPerStudy,
  mandatoryRedactBurnedInPHI,
  mandatoryDeidentifyList,
  removeBurnedInPHI,
  setRemoveBurnedInPHI,
  defaultDeidentifiedSubjectId,
  setNewUserDefinedDeidentifiedSubjectId,
}: AnonymizationProps) => {
  const { studyMetadata, dicomTagOverridesPerStudy, disallowedDeidentifyTags } = useFileState()

  const toggleRemoveBurnedInPHI = () => {
    setRemoveBurnedInPHI(!removeBurnedInPHI)
  }

  /**
   * Note: the LocalizationProvider is required to drive any date picker components in the table hierarchy.
   */
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ display: show ? 'block' : 'none' }}>
        <DeidentifiedPatientInfoInput
          subjectIdUnavailable={subjectIdUnavailable}
          defaultDeidentifiedSubjectId={defaultDeidentifiedSubjectId}
          setNewUserDefinedDeidentifiedSubjectId={setNewUserDefinedDeidentifiedSubjectId}
          isGuestUploader={isGuestUploader}
        />
        {studyMetadata.map((study) => {
          const { StudyInstanceUID } = study

          const setNewDicomTagOverride = (keyword: string, value: string) => setNewDicomTagOverrideForStudy(StudyInstanceUID, keyword, value)
          const addNewUserDefinedRow = (keyword: string) => addNewUserDefinedRowForStudy(StudyInstanceUID, keyword)
          const deleteUserDefinedRow = (keyword: string) => deleteUserDefinedRowForStudy(StudyInstanceUID, keyword)

          return (
            <AnonymizationTable
              key={study.StudyInstanceUID}
              studyMetadata={study}
              mandatoryDeidentifyList={mandatoryDeidentifyList}
              userDefinedDeidentifyList={newUserDefinedDeidentifyListPerStudy[StudyInstanceUID]}
              dicomTagOverrides={dicomTagOverridesPerStudy[StudyInstanceUID]}
              setNewDicomTagOverride={setNewDicomTagOverride}
              addNewUserDefinedRow={addNewUserDefinedRow}
              deleteUserDefinedRow={deleteUserDefinedRow}
            />
          )
        })}
      </div>
      {disallowedDeidentifyTags.length ?
        <p className="anonymization-disallowed-tags">
          {disallowedDeidentifyTagsMessage(disallowedDeidentifyTags)}
        </p> : null
      }

      <h4 style={{ textAlign: "left" }}>Other Options</h4>

      <CheckBox
        title="Remove PHI that is burned into images"
        value={removeBurnedInPHI}
        onToggle={toggleRemoveBurnedInPHI}
        fixedOn={mandatoryRedactBurnedInPHI}
      />
    </LocalizationProvider>
  );
}

export default Anonymization;