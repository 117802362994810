import React from "react";
import CheckBox from "../Shared/CheckBox";
import { useFileState } from "../../providers/FileStateProvider";
import "./RewriteUIDs.css";

const RewriteUIDs = () => {
    const { rewriteUIDs, api } = useFileState();

    const toggleRewriteUIDs = () => {
        api.setRewriteUIDs(!rewriteUIDs)
    };

    return (
        <div className="rewrite-uids-container">
            <CheckBox
                title="Rewrite DICOM UIDs"
                value={rewriteUIDs}
                onToggle={toggleRewriteUIDs}
            />
        </div >
    );
};

export default RewriteUIDs;