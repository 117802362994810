import React from 'react'
import StyledReactSelect from './StyledReactSelect'

type Option = {
    value: string,
    label: string
}

type SelectInputProps = {
    placeholder?: string;
    selectedOption?: Option;
    options: Option[];
    onChange: (value: string) => void;
}

const StatelessSelectInput = ({ placeholder, selectedOption, onChange, options }: SelectInputProps) => {
    const onSelectChange = (option: any) => {
        onChange(option.value)
    }

    return (
        <StyledReactSelect
            placeholder={placeholder}
            selectedOption={selectedOption}
            options={options}
            onChange={onSelectChange}
        />
      );
}

export default StatelessSelectInput