import React from 'react';
import YunuHeader from './YunuHeader';
import UploadHeader from './UploadHeader';
import YunuFooter from './YunuFooter';
import "./PageWrapper.css"
import { OrderResponse, SitePatientsForPatientResponse, TrialResponse } from '../../../sharedTypes/wmsQueryTypes';

type PageWrapperProps = {
    trialId?: string,
    trial?: TrialResponse,
    patient?: SitePatientsForPatientResponse,
    order?: OrderResponse,
    isNonTemporaryGuestUploader?: boolean
    children: React.ReactNode | React.ReactNode[]
}


function PageWrapper({
    trialId,
    trial,
    patient,
    order,
    isNonTemporaryGuestUploader,
    children
}: PageWrapperProps) {
    return (
        <div className="page-wrapper">
            <YunuHeader />
            <UploadHeader
                trialId={trialId}
                trial={trial}
                patient={patient}
                order={order}
                isNonTemporaryGuestUploader={isNonTemporaryGuestUploader}
            />
            {children}
            <YunuFooter />
        </div>
    )
}

export default PageWrapper