/** Note we have to duplicate this on the client and server as you can't use enums or const enums in .t.ds files*/
enum UploadStatus {
    CREATED = 0, // Instance is created.
    INITIALIZED = 1, // URLs are signed.
    UPLOADING = 2, // Data is uploading to GCP Bucket.
    PROCESSING = 3, // Job Platform processing
    COMPLETE = 4, // Job Platform complete, can read status and display.
    FAILED = 5 // Job Platform failed to process.
}

export { UploadStatus }