import { getDicomHeader, isDicom, NaturalizedHeader } from "./dicom"
import { ExtendedDicomFile } from "./types"


const isDerived = (header: NaturalizedHeader): boolean => {
    const { ImageType } = header;

    // ImageType is type 3, we can't assume its not derived if its not present, so return true.
    if (!ImageType) {
        return true;
    }

    const imageTypeArray = Array.isArray(ImageType) ? ImageType : [ImageType];

    for (let i = 0; i < imageTypeArray.length; i++) {
        const imageTypeValue = imageTypeArray[i];

        // Protect against bad data. Some data we've seen has the delimiter the wrong way,
        // E.g. DERIVED/SECONDARY rather than DERIVED\SECONDARY. By using includes instead we mitigate against this (in that case it will come in as one string).
        if (imageTypeValue.includes('DERIVED')) {
            return true;
        }
    }

    return false;
}

const getExtendedDicomFile = async (file: File): Promise<ExtendedDicomFile> => {
    const extendedFile = file as ExtendedDicomFile
    const isDicomFile = await isDicom(file)

    if (isDicomFile) {
        // TODO -> We will keep this around for a while and inject trial level logic in the future (US879), but not for now.
        const header = await getDicomHeader(file);

        const {
            StudyInstanceUID,
            SeriesInstanceUID,
            SOPInstanceUID,
            SeriesDescription
        } = header;

        extendedFile.isDicom = true;
        extendedFile.isDerived = isDerived(header);
        extendedFile.StudyInstanceUID = StudyInstanceUID;
        extendedFile.SeriesInstanceUID = SeriesInstanceUID;
        extendedFile.SeriesDescription = SeriesDescription;
        extendedFile.SOPInstanceUID = SOPInstanceUID;
    } else {
        extendedFile.isDicom = false;
        extendedFile.isDerived = false;
        extendedFile.StudyInstanceUID = '';
        extendedFile.SeriesInstanceUID = '';
        extendedFile.SeriesDescription = '';
        extendedFile.SOPInstanceUID = '';
    }

    return extendedFile
}

export { getExtendedDicomFile }