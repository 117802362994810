import React, { useState } from "react";
import { DicomTagOverridesPerStudy, useFileState } from "../../providers/FileStateProvider";
import { UserDefinedDeidentifyListPerStudy } from "../../lib/deidentification/types";
import "./UserInput.css";
import UserInputFooterDeidentified from "./UserInputFooterDeidentified";
import AttachmentsWithDropDown from "./AttachmentsWithDropDown";
import AnonymizationWithDropDown from "./AnonymizationWithDropdown";

const DeidentifiedOrderLevelInput = () => {
    const {
        dicomTagOverridesPerStudy,
        userDefinedDeidentifyListPerStudy,
        manualRedactBurnedInPHI,
        mandatoryRedactBurnedInPHI,
        mandatoryDeidentifyList,
        uploadInput,
        siteId: selectedSiteId
    } = useFileState()

    const subjectId = uploadInput?.patient?.subjectId;

    // Note this is the local state, which we then add to the global state after
    // we confirm or go back.
    const [newDicomTagOverridesPerStudy, setNewDicomTagOverridesPerStudy] = useState<DicomTagOverridesPerStudy>(dicomTagOverridesPerStudy)
    const [newUserDefinedDeidentifyListPerStudy, setNewUserDefinedDeidentifyListPerStudy] = useState<UserDefinedDeidentifyListPerStudy>(userDefinedDeidentifyListPerStudy)
    const [removeBurnedInPHI, setRemoveBurnedInPHI] = useState<boolean>(manualRedactBurnedInPHI)

    return (
        <div className="user-input-layout">
            <AnonymizationWithDropDown
                subjectIdUnavailable={false}
                isGuestUploader={!!uploadInput.guestUploader}
                defaultDeidentifiedSubjectId={subjectId}
                setNewDicomTagOverridesPerStudy={setNewDicomTagOverridesPerStudy}
                setNewUserDefinedDeidentifyListPerStudy={setNewUserDefinedDeidentifyListPerStudy}
                newUserDefinedDeidentifyListPerStudy={newUserDefinedDeidentifyListPerStudy}
                removeBurnedInPHI={removeBurnedInPHI}
                setRemoveBurnedInPHI={setRemoveBurnedInPHI}
                mandatoryRedactBurnedInPHI={mandatoryRedactBurnedInPHI}
                mandatoryDeidentifyList={mandatoryDeidentifyList}
            />
            <AttachmentsWithDropDown />
            <UserInputFooterDeidentified
                canUpload={true}
                removeBurnedInPHI={removeBurnedInPHI}
                defaultDeidentifiedSubjectId={subjectId}
                newDicomTagOverridesPerStudy={newDicomTagOverridesPerStudy}
                newUserDefinedDeidentifyListPerStudy={newUserDefinedDeidentifyListPerStudy}
                selectedSiteId={selectedSiteId}
            />
        </div>
    );
}


export default DeidentifiedOrderLevelInput;