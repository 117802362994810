import React from 'react';
import version from '../version'
import './YunuFooter.css'


function YunuFooter() {
    return (
        <div className='yunu-footer-container'>
            <p className='yunu-footer'>{`v${version}`}</p>
        </div>
    )
}

export default YunuFooter