import { DICOMFormatPatientInfo } from "./types";
import { TagPatchConfig } from "../../../../sharedTypes/deidentifyTypes";
import { SitePatientInfo } from "../../../../sharedTypes/wmsQueryTypes";
import { formatDicomDateToWMSDate } from "../format";

const combinePatientInformation = (
    defaultPatientInfo: DICOMFormatPatientInfo | undefined,
    userDefinedPatientInfo: Partial<DICOMFormatPatientInfo>
): DICOMFormatPatientInfo => {
    const PatientID = userDefinedPatientInfo.PatientID ? userDefinedPatientInfo.PatientID : defaultPatientInfo?.PatientID
    const PatientName = userDefinedPatientInfo.PatientName ? userDefinedPatientInfo.PatientName : defaultPatientInfo?.PatientName
    const PatientBirthDate = userDefinedPatientInfo.PatientBirthDate ? userDefinedPatientInfo.PatientBirthDate : defaultPatientInfo?.PatientBirthDate
    const PatientSex = userDefinedPatientInfo.PatientSex ? userDefinedPatientInfo.PatientSex : defaultPatientInfo?.PatientSex

    if (PatientID === undefined || PatientName === undefined || PatientBirthDate === undefined || PatientSex === undefined) {
        throw new Error('Incomplete patient information')
    }

    return {
        PatientID,
        PatientName,
        PatientBirthDate,
        PatientSex
    }
}

const generateWMSPatientInformation = (
    defaultPatientInfo: DICOMFormatPatientInfo | undefined,
    userDefinedPatientInfo: Partial<DICOMFormatPatientInfo>
): SitePatientInfo => {
    const { PatientID, PatientName, PatientBirthDate, PatientSex } = combinePatientInformation(defaultPatientInfo, userDefinedPatientInfo)

    const splitName = PatientName.value.split(' ')

    const FirstName = splitName[0];
    let LastName;

    if (splitName.length === 1) {
        LastName = 'UNKNOWN';
    } else {
        LastName = splitName[splitName.length - 1];
    }

    const MedicalRecordNumber = PatientID.value;

    const DateOfBirth = formatDicomDateToWMSDate(PatientBirthDate.value)

    let Gender: SitePatientInfo['Gender'];

    const patientSexValue = PatientSex.value

    if (patientSexValue === 'F') {
        Gender = 'Female'
    } else if (patientSexValue === 'M') {
        Gender = 'Male'
    } else {
        Gender = 'Unknown'
    }

    return {
        FirstName,
        LastName,
        DateOfBirth,
        Gender,
        MedicalRecordNumber
    }
}

const generatePatientTagPatchConfig = (
    defaultPatientInfo: DICOMFormatPatientInfo | undefined,
    userDefinedPatientInfo: Partial<DICOMFormatPatientInfo>
): TagPatchConfig => {
    const { PatientID, PatientName, PatientBirthDate, PatientSex } = combinePatientInformation(defaultPatientInfo, userDefinedPatientInfo)

    return [
        {
            op: 'add',
            path: 'PatientID',
            value: {
                vr: 'LO',
                Value: [PatientID.value]
            }
        },
        {
            op: 'add',
            path: 'PatientName',
            value: {
                vr: 'PN',
                Value: [PatientName.value.replaceAll(' ', '^')]
            }
        },
        {
            op: 'add',
            path: 'PatientBirthDate',
            value: {
                vr: 'DA',
                Value: [PatientBirthDate.value]
            }
        },
        {
            op: 'add',
            path: 'PatientSex',
            value: {
                vr: 'CS',
                Value: [PatientSex.value]
            }
        },
    ]
}

export { generatePatientTagPatchConfig, generateWMSPatientInformation }