import { UploadItem } from "../../../../sharedTypes/bucketUploadTypes";
import { auth0Axios } from "../../auth/auth0Axios";
import { isCurrentUploaderInstanceId } from "../../providers/utils/uploadCancelledState";

const fetchDicomSignedUrls = async (uploaderInstanceId: string, minIndex: number, maxIndex: number): Promise<UploadItem[] | undefined> => {
    return new Promise((resolve) => {
        auth0Axios.get(`/api/uploader/instance/signed-urls/dicom/${uploaderInstanceId}/min/${minIndex}/max/${maxIndex}/`).then(result => {
            const uploadItems: UploadItem[] = result.data.uploadItems

            resolve(uploadItems)
        }).catch((error) => {
            console.error(error);
            resolve(undefined)
        })
    })
}

const fetchAttachmentSignedUrls = async (uploaderInstanceId: string): Promise<UploadItem[] | undefined> => {
    return new Promise((resolve) => {
        auth0Axios.get(`/api/uploader/instance/signed-urls/attachments/${uploaderInstanceId}/`).then(result => {
            const uploadItems: UploadItem[] = result.data.uploadItems

            resolve(uploadItems)
        }).catch((error) => {
            console.error(error);
            resolve(undefined)
        })
    })
}


const requestDICOMSignedUrls = async (uploaderInstanceId: string, minIndex: number, maxIndex: number): Promise<UploadItem[]> => {
    return new Promise((resolve, reject) => {
        const startAttemptingToGetSignedUrls = async () => {
            if (!isCurrentUploaderInstanceId(uploaderInstanceId)) {
                reject();
            }
            const result = await fetchDicomSignedUrls(uploaderInstanceId, minIndex, maxIndex);

            if (result !== undefined) {
                resolve(result)
            } else {
                startAttemptingToGetSignedUrls()
            }
        }

        startAttemptingToGetSignedUrls()
    })
}

const requestAttachmentSignedUrls = async (uploaderInstanceId: string): Promise<UploadItem[]> => {
    return new Promise((resolve, reject) => {
        const startAttemptingToGetSignedUrls = async () => {
            if (!isCurrentUploaderInstanceId(uploaderInstanceId)) {
                reject();
            }

            const result = await fetchAttachmentSignedUrls(uploaderInstanceId);

            if (result !== undefined) {
                resolve(result)
            } else {
                startAttemptingToGetSignedUrls()
            }
        }

        startAttemptingToGetSignedUrls()
    })
}

export { requestDICOMSignedUrls, requestAttachmentSignedUrls }