import React, { useEffect, useState } from "react";
import './GuestUploaderSelectData.css'
import SelectDataFooter from "../SelectDataFooter";
import DicomSelector from "../DicomSelector";
import { useParams } from "react-router-dom";
import { GuestUploader, GuestUploaderSenderInfo } from "../../../../sharedTypes/bucketUploadTypes";
import { useFileState } from "../../providers/FileStateProvider";
import SelectStudies from "../Shared/SelectStudies";


const isValidEmailFormat = (email?: string): boolean => {
    if (!email?.includes('@')) {
        return false
    }

    const sections = email.split('@')

    if (sections.length !== 2) {
        return false
    }

    const domain = sections[1]

    if (!domain.includes('.')) {
        return false
    }

    // Check domain has something after the dot
    const splitDomain = domain.split('.')

    const lastElement = splitDomain[splitDomain.length - 1]

    return lastElement.length > 0
}

const isValidInput = (firstName?: string, lastName?: string, institution?: string, email?: string): boolean => {
    const empty = ['', undefined]

    if (empty.includes(firstName) || empty.includes(lastName) || empty.includes(institution) || empty.includes(email)) {
        return false
    }

    return true
}

const GuestUploaderSelectData = ({ guestUploader }: { guestUploader: GuestUploader }) => {
    const { trial_id } = useParams()
    const { api } = useFileState()

    // If we have sender_info on guest uploader => insert the form.

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [institution, setInstitution] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState<number | undefined>(undefined)
    const [internationalCode, setInternationalCode] = useState<number | undefined>(undefined)
    const [selectDataDisabled, setSelectDataDisabled] = useState<boolean>(guestUploader.require_sender_info)
    const [validEmailFormat, setValidEmailFormat] = useState<boolean>(false)


    const formatAndSetInternationalCode = (code: string): void => {
        let value: number | undefined

        if (code.length === 0) {
            value = undefined
        } else if (code.length > 3) {
            value = Number(code.slice(0, 3))
        } else {
            value = Number(code)
        }

        setInternationalCode(value)
    }

    const formatAndSetPhoneNumber = (code: string): void => {
        let value: number | undefined

        if (code.length === 0) {
            value = undefined
        } else if (code.length > 14) {
            // Max 14 digits after area code by international standard
            value = Number(code.slice(0, 14))
        } else {
            value = Number(code)
        }

        setPhoneNumber(value)
    }

    const validateAndSetEmail = (email: string): void => {
        setValidEmailFormat(isValidEmailFormat(email))
        setEmail(email)
    }


    useEffect(() => {
        // Only run this logic if we have require sender info
        if (!guestUploader.require_sender_info) {
            return
        }

        if (isValidInput(firstName, lastName, institution, email) && validEmailFormat) {
            setSelectDataDisabled(false)
        } else {
            setSelectDataDisabled(true)
        }

        const guestUploaderInfo: GuestUploaderSenderInfo = {
            first_name: firstName,
            last_name: lastName,
            institution,
            email_address: email,
        }

        // Only include phone number if both bits are given
        if (internationalCode !== undefined && phoneNumber !== undefined) {
            guestUploaderInfo.phone_number = {
                international_code: Number(internationalCode),
                number: Number(phoneNumber)
            }
        }

        api.setGuestSenderInfo(guestUploaderInfo)
    }, [firstName, lastName, institution, email, phoneNumber, validEmailFormat])

    return (
        <div className="guest-uploader-select-data">
            {guestUploader.uploader_message ? <h2>{guestUploader.uploader_message}</h2> : null}
            {guestUploader.require_sender_info ?
                <div>
                    <p className="guest-uploader-required-info-message">Please provide your contact information:</p>
                    <div className="guest-uploader-required-info-input">
                        <p style={{ gridColumnStart: 1, gridRowStart: 1 }}>Name<span className="required-info-asterisk">*</span></p>
                        <input type="text" placeholder="First" style={{ gridColumnStart: 2, gridColumnEnd: 5, gridRowStart: 1 }} onChange={(evt) => setFirstName(evt.target.value)}></input>
                        <input type="text" placeholder="Last" style={{ gridColumnStart: 5, gridRowStart: 1 }} onChange={(evt) => setLastName(evt.target.value)}></input>
                        <p style={{ gridColumnStart: 1, gridRowStart: 2 }}>Institution<span className="required-info-asterisk">*</span></p>
                        <input type="text" placeholder="Institution" style={{ gridColumnStart: 2, gridColumnEnd: 6, gridRowStart: 2 }} onChange={(evt) => setInstitution(evt.target.value)}></input>
                        <p style={{ gridColumnStart: 1, gridRowStart: 3 }}>Email Address<span className="required-info-asterisk">*</span></p>
                        <input type="text" placeholder="example@institution.com" style={{ gridColumnStart: 2, gridColumnEnd: 6, gridRowStart: 3 }} onChange={(evt) => validateAndSetEmail(evt.target.value)}></input>
                        <p style={{ gridColumnStart: 1, gridRowStart: 4 }}>Phone Number</p>
                        <p style={{ gridColumnStart: 2, gridRowStart: 4 }}>+</p>
                        <input type="number" placeholder="1" style={{ gridColumnStart: 3, gridRowStart: 4 }} value={internationalCode} onChange={(evt) => formatAndSetInternationalCode(evt.target.value)}></input>
                        <input type="number" placeholder="0000000000" style={{ gridColumnStart: 4, gridColumnEnd: 6, gridRowStart: 4 }} value={phoneNumber} onChange={(evt) => formatAndSetPhoneNumber(evt.target.value)}></input>
                    </div>
                    {!validEmailFormat && email.length > 0 ? <p className="guest-uploader-invalid-email"> Please enter a valid email address</p> : null}
                </div>
                : null}
            <DicomSelector
                disabled={selectDataDisabled}
            />
            <SelectStudies />
            <SelectDataFooter
                canUploadAttachments={trial_id !== undefined}
            />
        </div>
    );
}


export default GuestUploaderSelectData