import { SetUploadInputInterface, UploadLevel } from "../FileStateProvider";

const getUploadLevel = (uploadInput: SetUploadInputInterface): UploadLevel => {
    const { trial_patient_id, order_id, trial_id } = uploadInput;

    if (
        trial_id === undefined &&
        trial_patient_id === undefined &&
        order_id === undefined
    ) {
        return UploadLevel.ORGANIZATION;
    }

    if (
        trial_id !== undefined &&
        trial_patient_id === undefined &&
        order_id === undefined
    ) {
        return UploadLevel.TRIAL;
    }

    if (
        trial_id !== undefined &&
        trial_patient_id !== undefined &&
        order_id !== undefined
    ) {
        return UploadLevel.ORDER;
    }


    throw new Error(
        'Invalid trial_id/patient_id/order_id configuration.'
    );
};

export { getUploadLevel }