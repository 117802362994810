import React, { createContext, useContext, useEffect, useRef, useCallback, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import throttle from 'lodash.throttle'
import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams, useNavigate } from "react-router-dom";
import { SITE_URL_LOCAL_STORAGE_KEY } from '../auth/localStorageKeys';
import { updateRollbarWithLoginInfo } from '../rollbar.setup';

// Keep this value up to date within TIMC.Web/Scripts/app/common/services/SessionService.js (WMS)
const WMS_LOGIN_TIMEOUT_MS = 30 * 60 * 1000; // Login timeout (30 minutes) in miliseconds

const inactivityTimeout = 30 * 60 * 1000; // 30mins

const onActiveDetectionHandlerThrottle = 60000; // 60 seconds

// See react-idle-timer default events:
//  https://github.com/SupremeTechnopriest/react-idle-timer#default-events
const ACTIVITY_EVENTS = [
    'mousemove',
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
];

let wmsSessionLastActionInterval = null;

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);


let auth0Token = ''

let promiseResolver;


const setAuth0Token = (newAuth0Token) => {
  auth0Token = newAuth0Token
  updateRollbarWithLoginInfo(newAuth0Token);
};

/**
 * Promise that allows us to determine when we have got an auth0 token for API usage.
 */
export const auth0TokenPromise = new Promise((resolve) => {
  promiseResolver = resolve
})

export const getAuth0Token = () => auth0Token

function AuthProvider({ children }) {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  let siteUrl = searchParams.get('siteUrl')

  if (!siteUrl) {
    siteUrl = localStorage.getItem(SITE_URL_LOCAL_STORAGE_KEY)
  }

  const [wmsIntegrationIframeRef, setWmsIntegrationIframeRef] = useState(null)

  /**
   * Note that refs are not reactive, i.e. we cannot see when it is changed in a useEffect.
   * This is an official work around, see: https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
   */
  const wmsIntegrationIframeRefCapture = useCallback(node => {
    if (node !== null) {
      setWmsIntegrationIframeRef(node);
    }
  }, []);

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  let activeInterval = null;

  const iapIframeRef = useRef(null);


  const { identityAwareProxyEnabled } = window.config || {};

  const _logout = () => {
    localStorage.setItem('loginRedirect', window.location.href);
    if (identityAwareProxyEnabled && iapIframeRef.current) {
      iapIframeRef.current.addEventListener('load', () => {
        navigate('/logout');
      });

      iapIframeRef.current.src = `${window.origin}?gcp-iap-mode=GCIP_SIGNOUT`;
    } else {
      navigate('/logout');
    }
  }

  const doLogout = () => {
    const iframe = wmsIntegrationIframeRef;
    // NOTE: For local dev bypass this as the fact we can't get the frame means we get an error
    if (iframe && window.location.origin !== "http://localhost:3000") {
      // Log user out in WMS if user is logged out in Uploader
      iframe.removeEventListener('load', _initializeWMSIntegrationIFrame);
      iframe.addEventListener('load', () => {
        console.log(
          'iframe has loaded Logout page, redirecting to Auth0 logout'
        );
        _logout();
      });

      iframe.src = `https://${siteUrl}/App/Logout`;
    } else {
      _logout();
    }
  };

  /* This request will keep the date of the last request made by the user updated.
   * In each request, the date of the last request is updated. In case the user does
   * not make new requests for more than 30 minutes, the next requests
   * will be invalidated in the backend.
   */
  useEffect(() => {
    const getAuthToken = () => {
      getAccessTokenSilently().then(token => {
        setAuth0Token(token);
      }).catch(console.error);
    };
    activeInterval = setInterval(getAuthToken, 1000 * 60 * 25);

    return () => {
      clearInterval(activeInterval);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then(token => {
        setAuth0Token(token);
        promiseResolver();
      }).catch(console.error);
    }
  }, [isAuthenticated, getAccessTokenSilently])


    // Listen for messages from WMS integration iframe
    useEffect(() => {
      const handleMessage = (event) => {
          if (event.origin !== `https://${siteUrl}`) {
              return;
          }

          if (event.data) {
              localStorage.setItem('ls.session.lastAction', event.data);
          }
      };

      window.addEventListener('message', handleMessage);

      return () => {
        window.removeEventListener('message', handleMessage);
      };
  }, [siteUrl]);

  const _requestWMSSessionLastAction = () => {
      const iframe = wmsIntegrationIframeRef;
      if (iframe) {
        iframe.contentWindow?.postMessage(
            'getSessionLastAction',
            `https://${siteUrl}`
        );
      }
  };

  const _initializeWMSListener = () => {
    const iframe = wmsIntegrationIframeRef;
    if (iframe) {
      iframe.contentWindow?.postMessage('initialize', `https://${siteUrl}`);
    }
  };

  const _initializeWMSIntegrationIFrame = () => {
    _initializeWMSListener();
    _requestWMSSessionLastAction();
  };


  // Update WMS session last action on page load
  useEffect(() => {
      const iframe = wmsIntegrationIframeRef;

      if (iframe) {
          iframe.addEventListener('load', _initializeWMSIntegrationIFrame);
      }

      return () => {
          if (iframe) {
              iframe.removeEventListener('load', _initializeWMSIntegrationIFrame);
          }
      };
  }, [siteUrl, wmsIntegrationIframeRef]);

  // Update WMS session last action periodically
  //  to keep the viewer session alive as long as WMS session is active
  if (siteUrl) {
      if (wmsSessionLastActionInterval) {
          clearInterval(wmsSessionLastActionInterval);
          wmsSessionLastActionInterval = null;
      }

      wmsSessionLastActionInterval = setInterval(
          () => {
            _requestWMSSessionLastAction();
          },
          Math.min(Math.floor(inactivityTimeout / 4)),
          60000
      );
  }

  const onIdleHandler = () => {
    const wmsLastAction = localStorage.getItem('ls.session.lastAction');

    if (wmsLastAction) {
      const wmsLastActionTime = parseInt(wmsLastAction, 10);
      const NOW = new Date().getTime();
      if (wmsLastActionTime + WMS_LOGIN_TIMEOUT_MS > NOW) {
        console.log(`AuthProvider: User is active in WMS on idle handler`);
        return;
      }
    } else {
      console.warn(
        'Unable to determine whether user is active in WMS on idle handler!'
      );
    }

    if (isAuthenticated) {
      console.log('User is idle!');
      console.log('Logging out...');

      doLogout();
    }
  }

  const updateWMSSessionLastAction = () => {
      const iframe = wmsIntegrationIframeRef;

      if (iframe) {
          const NOW = new Date().getTime();
          iframe.contentWindow?.postMessage(
              `setSessionLastAction:${NOW}`,
              `https://${siteUrl}`
          );
      }
  };

  // Handle user activity once every 1 min as long as user is active anytime
  const onActiveDetectionHandler = throttle(() => {
      // Update WMS session last action time on user activity
      if (siteUrl) {
          updateWMSSessionLastAction();
      }
  }, onActiveDetectionHandlerThrottle);

  return (
    <AuthContext.Provider value={{ logout: doLogout }}>
      {identityAwareProxyEnabled && (
        <iframe
          title="iap-iframe"
          ref={iapIframeRef}
          src={`${window.origin}?gcp-iap-mode=DO_SESSION_REFRESH`}
          style={{
            width: 0,
            height: 0,
            display: 'none',
            border: 'none',
          }}
        />
      )}
      {siteUrl && (
          <iframe
          ref={wmsIntegrationIframeRefCapture}
          src={`https://${siteUrl}/App/IntegrationMode`}
          style={{
              width: 0,
              height: 0,
              display: 'none',
              border: 'none',
          }}
          />
      )}
      <IdleTimer
          key='idle-timer'
          element={document}
          events={ACTIVITY_EVENTS}
          onIdle={onIdleHandler}
          debounce={1000}
          timeout={inactivityTimeout}
      />
      <IdleTimer
          key='active-timer'
          element={document}
          events={ACTIVITY_EVENTS}
          onActive={onActiveDetectionHandler}
          timeout={0}
      />
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
