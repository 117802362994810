import { TailSpin } from 'react-loader-spinner';
import "./LoadingSpinner.css";

const LoadingSpinner = () => {
    return (
        <div className='loading-spinner'>
            <TailSpin
                visible={true}
                height="12"
                width="12"
                color="var(--text-primary)"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}
                }
                wrapperClass=""
            />
        </div>
    )
};

export default LoadingSpinner;