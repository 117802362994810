/**
 * This file contains maps for any tag we support with a vr of CS, which needs to
 * be made into a select rather than free text.
 */


type CodeStringMap = {
    selectOptions: string[],
    humanReadable: Record<string,string>
}

const PatientSex = {
    selectOptions: ["M", "F", "O"],
    humanReadable: {
        "M": "Male",
        "F": "Female",
        "O": "Other"
    }
}

const QualityControlSubject = {
    selectOptions: ["YES", "NO"],
    humanReadable: {
        "YES": "Yes",
        "NO": "No"
    }
}

const ResponsiblePersonRole = {
    selectOptions: [
        "OWNER",
        "PARENT",
        "CHILD",
        "SPOUSE",
        "SIBLING",
        "RELATIVE",
        "GUARDIAN",
        "CUSTODIAN",
        "AGENT",
        "INVESTIGATOR",
        "VETERINARIAN",
    ],
    humanReadable: {
        "OWNER": "Owner",
        "PARENT": "Parent",
        "CHILD": "Child",
        "SPOUSE": "Spouse",
        "SIBLING": "Sibling",
        "RELATIVE": "Relative",
        "GUARDIAN": "Guardian",
        "CUSTODIAN": "Custodian",
        "AGENT": "Agent",
        "INVESTIGATOR": "Investigator",
        "VETERINARIAN": "Veterinarian",
    }
}

const SmokingStatus = {
    selectOptions: [
        "YES",
        "NO",
        "UNKNOWN"
    ],
    humanReadable: {
        "YES": "Yes",
        "NO": "No",
        "UNKNOWN": "Unknown"
    }
}

const BodyPartExamined = {
    selectOptions:[
        "ABDOMEN",
        "ABDOMENPELVIS",
        "ABDOMINALAORTA",
        "ACJOINT",
        "ADRENAL",
        "AMNIOTICFLUID",
        "ANKLE",
        "ANTECUBITALV",
        "ANTCARDIACV",
        "ACA",
        "ANTCOMMA",
        "ANTSPINALA",
        "ANTTIBIALA",
        "ANUSRECTUMSIGMD",
        "AORTA",
        "AORTICARCH",
        "APPENDIX",
        "ARTERY",
        "ASCAORTA",
        "ASCENDINGCOLON",
        "AXILLA",
        "AXILLARYA",
        "AXILLARYV",
        "AZYGOSVEIN",
        "BACK",
        "BASILARA",
        "BILEDUCT",
        "BILIARYTRACT",
        "BLADDER",
        "BLADDERURETHRA",
        "BRACHIALA",
        "BRACHIALV",
        "BRAIN",
        "BREAST",
        "BRONCHUS",
        "BUTTOCK",
        "CALCANEUS",
        "CALF",
        "CAROTID",
        "BULB",
        "CELIACA",
        "CEPHALICV",
        "CEREBELLUM",
        "CEREBRALA",
        "CEREBHEMISPHERE",
        "CSPINE",
        "CTSPINE",
        "CERVIX",
        "CHEEK",
        "CHEST",
        "CHESTABDPELVIS",
        "CHESTABDOMEN",
        "CHOROIDPLEXUS",
        "CIRCLEOFWILLIS",
        "CLAVICLE",
        "COCCYX",
        "COLON",
        "COMMONBILEDUCT",
        "CCA",
        "CFA",
        "CFV",
        "COMILIACA",
        "COMILIACV",
        "CORNEA",
        "CORONARYARTERY",
        "CORONARYSINUS",
        "DESCAORTA",
        "DESCENDINGCOLON",
        "DUODENUM",
        "EAR",
        "ELBOW",
        "ENDOARTERIAL",
        "ENDOCARDIAC",
        "ENDOESOPHAGEAL",
        "ENDOMETRIUM",
        "ENDONASAL",
        "ENDONASOPHARYNYX",
        "ENDORECTAL",
        "ENDORENAL",
        "ENDOURETERIC",
        "ENDOURETHRAL",
        "ENDOVAGINAL",
        "ENDOVASCULAR",
        "ENDOVENOUS",
        "ENDOVESICAL",
        "WHOLEBODY",
        "EPIDIDYMIS",
        "EPIGASTRIC",
        "ESOPHAGUS",
        "EAC",
        "ECA",
        "EXTILIACA",
        "EXTILIACV",
        "EXTJUGV",
        "EXTREMITY",
        "EYE",
        "EYELID",
        "FACE",
        "FACIALA",
        "FEMORALA",
        "FEMORALV",
        "FEMUR",
        "FETALARM",
        "FETALDIGIT",
        "FETALHEART",
        "FETALLEG",
        "FETALPOLE",
        "FIBULA",
        "FINGER",
        "FLANK",
        "FONTANEL",
        "FOOT",
        "FOREARM",
        "4THVENTRICLE",
        "GALLBLADDER",
        "GASTRICV",
        "GENICULARA",
        "GESTSAC",
        "GLUTEAL",
        "GSV",
        "HAND",
        "HEAD",
        "HEADNECK",
        "HEART",
        "HEPATICA",
        "HEPATICV",
        "HIP",
        "HUMERUS",
        "HYPOGASTRIC",
        "HYPOPHARYNX",
        "ILEUM",
        "ILIACA",
        "ILIACV",
        "ILIUM",
        "INFMESA",
        "INFVENACAVA",
        "INGUINAL",
        "INNOMINATEA",
        "INNOMINATEV",
        "IAC",
        "ICA",
        "INTILIACA",
        "INTJUGULARV",
        "INTMAMMARYA",
        "INTRACRANIAL",
        "JAW",
        "JEJUNUM",
        "JOINT",
        "KIDNEY",
        "KNEE",
        "LACRIMALA",
        "LARGEINTESTINE",
        "LARYNX",
        "LATVENTRICLE",
        "LATRIUM",
        "LFEMORALA",
        "LHEPATICV",
        "LHYPOCHONDRIAC",
        "LINGUINAL",
        "LLQ",
        "LLUMBAR",
        "LPORTALV",
        "LPULMONARYA",
        "LUQ",
        "LVENTRICLE",
        "LINGUALA",
        "LIVER",
        "LOWERLEG",
        "LOWERLIMB",
        "LUMBARA",
        "LUMBAR",
        "LSPINE",
        "LSSPINE",
        "LUMEN",
        "LUNG",
        "MANDIBLE",
        "MASTOID",
        "MAXILLA",
        "MEDIASTINUM",
        "MESENTRICA",
        "MESENTRICV",
        "MCA",
        "MIDHEPATICV",
        "MORISONSPOUCH",
        "MOUTH",
        "NASOPHARYNX",
        "NECK",
        "NECKCHESTABDPELV",
        "NECKCHESTABDOMEN",
        "NECKCHEST",
        "NOSE",
        "OCCPITALA",
        "OCCIPTALV",
        "OPHTHALMICA",
        "OPTICCANAL",
        "ORBIT",
        "OVARY",
        "PANCREAS",
        "PANCREATICDUCT",
        "PANCBILEDUCT",
        "PARASTERNAL",
        "PARATHYROID",
        "PAROTID",
        "PATELLA",
        "PELVIS",
        "PELVISLOWEXTREMT",
        "PENILEA",
        "PENIS",
        "PERINEUM",
        "PERONEALA",
        "PHANTOM",
        "PHARYNX",
        "PHARYNXLARYNX",
        "PLACENTA",
        "POPLITEALA",
        "POPLITEALFOSSA",
        "POPLITEALV",
        "PORTALV",
        "PCA",
        "POSCOMMA",
        "POSTIBIALA",
        "PROFFEMA",
        "PROFFEMV",
        "PROSTATE",
        "PULMONARYA",
        "PULMONARYV",
        "RADIALA",
        "RADIUS",
        "RADIUSULNA",
        "CULDESAC",
        "RECTUM",
        "RENALA",
        "RENALV",
        "RETROPERITONEUM",
        "RIB",
        "RATRIUM",
        "RFEMORALA",
        "RHEPATICV",
        "RHYPOCHONDRIAC",
        "RINGUINAL",
        "RLQ",
        "RLUMBAR",
        "RPORTALV",
        "RPULMONARYA",
        "RUQ",
        "RVENTRICLE",
        "SIJOINT",
        "SSPINE",
        "SFJ",
        "SAPHENOUSV",
        "SCALP",
        "SCAPULA",
        "SCLERA",
        "SCROTUM",
        "SELLA",
        "SEMVESICLE",
        "SESAMOID",
        "SHOULDER",
        "SIGMOID",
        "SKULL",
        "SMALLINTESTINE",
        "SPINALCORD",
        "SPINE",
        "SPLEEN",
        "SPLENICA",
        "SPLENICV",
        "SCJOINT",
        "STERNUM",
        "STOMACH",
        "SUBCLAVIANA",
        "SUBCLAVIANV",
        "SUBCOSTAL",
        "SUBMANDIBULAR",
        "SFA",
        "SFV",
        "LSUPPULMONARYV",
        "SMA",
        "RSUPPULMONARYV",
        "SUPTHYROIDA",
        "SVC",
        "SUPRACLAVICULAR",
        "SUPRAPUBIC",
        "TMJ",
        "TESTIS",
        "THALAMUS",
        "THIGH",
        "3RDVENTRICLE",
        "THORACICAORTA",
        "TSPINE",
        "TLSPINE",
        "THORAX",
        "THUMB",
        "THYMUS",
        "THYROID",
        "TIBIA",
        "TIBIAFIBULA",
        "TOE",
        "TONGUE",
        "TRACHEA",
        "TRACHEABRONCHUS",
        "TRANSVERSECOLON",
        "ULNA",
        "ULNARA",
        "UMBILICALA",
        "UMBILICAL",
        "UMBILICALV",
        "UPPERARM",
        "UPPERLIMB",
        "UPRURINARYTRACT",
        "URETER",
        "URETHRA",
        "UTERUS",
        "VAGINA",
        "VEIN",
        "VERTEBRALA",
        "VULVA",
        "WRIST",
        "ZYGOMA",
    ],
    humanReadable: {
        "ABDOMEN":	"Abdomen",
        "ABDOMENPELVIS":	"Abdomen and Pelvis",
        "ABDOMINALAORTA":	"Abdominal aorta",
        "ACJOINT":	"Acromioclavicular joint",
        "ADRENAL":	"Adrenal gland",
        "AMNIOTICFLUID":	"Amniotic fluid",
        "ANKLE":	"Ankle joint",
        "ANTECUBITALV":	"Antecubital vein",
        "ANTCARDIACV":	"Anterior cardiac vein",
        "ACA":	"Anterior cerebral artery",
        "ANTCOMMA":	"Anterior communicating artery",
        "ANTSPINALA":	"Anterior spinal artery",
        "ANTTIBIALA":	"Anterior tibial artery",
        "ANUSRECTUMSIGMD":	"Anus, rectum and sigmoid colon",
        "AORTA":	"Aorta",
        "AORTICARCH":	"Aortic arch",
        "APPENDIX":	"Appendix",
        "ARTERY":	"Artery",
        "ASCAORTA":	"Ascending aorta",
        "ASCENDINGCOLON":	"Ascending colon",
        "AXILLA":	"Axilla",
        "AXILLARYA":	"Axillary Artery",
        "AXILLARYV":	"Axillary vein",
        "AZYGOSVEIN":	"Azygos vein",
        "BACK":	"Back",
        "BASILARA":	"Basilar artery",
        "BILEDUCT":	"Bile duct",
        "BILIARYTRACT":	"Biliary tract",
        "BLADDER":	"Bladder",
        "BLADDERURETHRA":	"Bladder and urethra",
        "BRACHIALA":	"Brachial artery",
        "BRACHIALV":	"Brachial vein",
        "BRAIN":	"Brain",
        "BREAST":	"Breast",
        "BRONCHUS":	"Bronchus",
        "BUTTOCK":	"Buttock",
        "CALCANEUS":	"Calcaneus",
        "CALF":	"Calf of leg",
        "CAROTID":	"Carotid Artery",
        "BULB":	"Carotid bulb",
        "CELIACA":	"Celiac artery",
        "CEPHALICV":	"Cephalic vein",
        "CEREBELLUM":	"Cerebellum",
        "CEREBRALA":	"Cerebral artery",
        "CEREBHEMISPHERE":	"Cerebral hemisphere",
        "CSPINE":	"Cervical spine",
        "CTSPINE":	"Cervico-thoracic spine",
        "CERVIX":	"Cervix",
        "CHEEK":	"Cheek",
        "CHEST":	"Chest",
        "CHESTABDPELVIS":	"Chest, Abdomen and Pelvis",
        "CHESTABDOMEN":	"Chest and Abdomen",
        "CHOROIDPLEXUS":	"Choroid plexus",
        "CIRCLEOFWILLIS":	"Circle of Willis",
        "CLAVICLE":	"Clavicle",
        "COCCYX":	"Coccyx",
        "COLON":	"Colon",
        "COMMONBILEDUCT":	"Common bile duct",
        "CCA":	"Common carotid artery",
        "CFA":	"Common femoral artery",
        "CFV":	"Common femoral vein",
        "COMILIACA":	"Common iliac artery",
        "COMILIACV":	"Common iliac vein",
        "CORNEA":	"Cornea",
        "CORONARYARTERY":	"Coronary artery",
        "CORONARYSINUS":	"Coronary sinus",
        "DESCAORTA":	"Descending aorta",
        "DESCENDINGCOLON":	"Descending colon",
        "DUODENUM":	"Duodenum",
        "EAR":	"Ear",
        "ELBOW":	"Elbow joint",
        "ENDOARTERIAL":	"Endo-arterial",
        "ENDOCARDIAC":	"Endo-cardiac",
        "ENDOESOPHAGEAL":	"Endo-esophageal",
        "ENDOMETRIUM":	"Endometrium",
        "ENDONASAL":	"Endo-nasal",
        "ENDONASOPHARYNYX":	"Endo-nasopharyngeal",
        "ENDORECTAL":	"Endo-rectal",
        "ENDORENAL":	"Endo-renal",
        "ENDOURETERIC":	"Endo-ureteric",
        "ENDOURETHRAL":	"Endo-urethral",
        "ENDOVAGINAL":	"Endo-vaginal",
        "ENDOVASCULAR":	"Endo-vascular",
        "ENDOVENOUS":	"Endo-venous",
        "ENDOVESICAL":	"Endo-vesical",
        "WHOLEBODY":	"Entire body",
        "EPIDIDYMIS":	"Epididymis",
        "EPIGASTRIC":	"Epigastric region",
        "ESOPHAGUS":	"Esophagus",
        "EAC":	"External auditory canal",
        "ECA":	"External carotid artery",
        "EXTILIACA":	"External iliac artery",
        "EXTILIACV":	"External iliac vein",
        "EXTJUGV":	"External jugular vein",
        "EXTREMITY":	"Extremity",
        "EYE":	"Eye",
        "EYELID":	"Eyelid",
        "FACE":	"Face",
        "FACIALA":	"Facial artery",
        "FEMORALA":	"Femoral artery",
        "FEMORALV":	"Femoral vein",
        "FEMUR":	"Femur",
        "FETALARM":	"Fetal arm",
        "FETALDIGIT":	"Fetal digit",
        "FETALHEART":	"Fetal heart",
        "FETALLEG":	"Fetal leg",
        "FETALPOLE":	"Fetal pole",
        "FIBULA":	"Fibula",
        "FINGER":	"Finger",
        "FLANK":	"Flank",
        "FONTANEL":	"Fontanel of skull",
        "FOOT":	"Foot",
        "FOREARM":	"Forearm",
        "4THVENTRICLE":	"Fourth ventricle",
        "GALLBLADDER":	"Gallbladder",
        "GASTRICV":	"Gastric vein",
        "GENICULARA":	"Genicular artery",
        "GESTSAC":	"Gestational sac",
        "GLUTEAL":	"Gluteal region",
        "GSV":	"Great saphenous vein",
        "HAND":	"Hand",
        "HEAD":	"Head",
        "HEADNECK":	"Head and Neck",
        "HEART":	"Heart",
        "HEPATICA":	"Hepatic artery",
        "HEPATICV":	"Hepatic vein",
        "HIP":	"Hip joint",
        "HUMERUS":	"Humerus",
        "HYPOGASTRIC":	"Hypogastric region",
        "HYPOPHARYNX":	"Hypopharynx",
        "ILEUM":	"Ileum",
        "ILIACA":	"Iliac artery",
        "ILIACV":	"Iliac vein",
        "ILIUM":	"Ilium",
        "INFMESA":	"Inferior mesenteric artery",
        "INFVENACAVA":	"Inferior vena cava",
        "INGUINAL":	"Inguinal region",
        "INNOMINATEA":	"Innominate artery",
        "INNOMINATEV":	"Innominate vein",
        "IAC":	"Internal Auditory Canal",
        "ICA":	"Internal carotid artery",
        "INTILIACA":	"Internal iliac artery",
        "INTJUGULARV":	"Internal jugular vein",
        "INTMAMMARYA":	"Internal mammary artery",
        "INTRACRANIAL":	"Intracranial",
        "JAW":	"Jaw region",
        "JEJUNUM":	"Jejunum",
        "JOINT":	"Joint",
        "KIDNEY":	"Kidney",
        "KNEE":	"Knee",
        "LACRIMALA":	"Lacrimal artery",
        "LARGEINTESTINE":	"Large intestine",
        "LARYNX":	"Larynx",
        "LATVENTRICLE":	"Lateral Ventricle",
        "LATRIUM":	"Left atrium",
        "LFEMORALA":	"Left femoral artery",
        "LHEPATICV":	"Left hepatic vein",
        "LHYPOCHONDRIAC":	"Left hypochondriac region",
        "LINGUINAL":	"Left inguinal region",
        "LLQ":	"Left lower quadrant of abdomen",
        "LLUMBAR":	"Left lumbar region",
        "LPORTALV":	"Left portal vein",
        "LPULMONARYA":	"Left pulmonary artery",
        "LUQ":	"Left upper quadrant of abdomen",
        "LVENTRICLE":	"Left ventricle",
        "LINGUALA":	"Lingual artery",
        "LIVER":	"Liver",
        "LOWERLEG":	"Lower leg",
        "LOWERLIMB":	"Lower limb",
        "LUMBARA":	"Lumbar artery",
        "LUMBAR":	"Lumbar region",
        "LSPINE":	"Lumbar spine",
        "LSSPINE":	"Lumbo-sacral spine",
        "LUMEN":	"Lumen of blood vessel",
        "LUNG":	"Lung",
        "MANDIBLE":	"Mandible",
        "MASTOID":	"Mastoid bone",
        "MAXILLA":	"Maxilla",
        "MEDIASTINUM":	"Mediastinum",
        "MESENTRICA":	"Mesenteric artery",
        "MESENTRICV":	"Mesenteric vein",
        "MCA":	"Middle cerebral artery",
        "MIDHEPATICV":	"Middle hepatic vein",
        "MORISONSPOUCH":	"Morisons pouch",
        "MOUTH":	"Mouth",
        "NASOPHARYNX":	"Nasopharynx",
        "NECK":	"Neck",
        "NECKCHESTABDPELV":	"Neck, Chest, Abdomen and Pelvis",
        "NECKCHESTABDOMEN":	"Neck, Chest and Abdomen",
        "NECKCHEST":	"Neck and Chest",
        "NOSE":	"Nose",
        "OCCPITALA":	"Occipital artery",
        "OCCIPTALV":	"Occipital vein",
        "OPHTHALMICA":	"Ophthalmic artery",
        "OPTICCANAL":	"Optic canal",
        "ORBIT":	"Orbital structure",
        "OVARY":	"Ovary",
        "PANCREAS":	"Pancreas",
        "PANCREATICDUCT":	"Pancreatic duct",
        "PANCBILEDUCT":	"Pancreatic duct and bile duct systems",
        "PARASTERNAL":	"Parasternal",
        "PARATHYROID":	"Parathyroid",
        "PAROTID":	"Parotid gland",
        "PATELLA":	"Patella",
        "PELVIS":	"Pelvis",
        "PELVISLOWEXTREMT":	"Pelvis and lower extremities",
        "PENILEA":	"Penile artery",
        "PENIS":	"Penis",
        "PERINEUM":	"Perineum",
        "PERONEALA":	"Peroneal artery",
        "PHANTOM":	"Phantom",
        "PHARYNX":	"Pharynx",
        "PHARYNXLARYNX":	"Pharynx and larynx",
        "PLACENTA":	"Placenta",
        "POPLITEALA":	"Popliteal artery",
        "POPLITEALFOSSA":	"Popliteal fossa",
        "POPLITEALV":	"Popliteal vein",
        "PORTALV":	"Portal vein",
        "PCA":	"Posterior cerebral artery",
        "POSCOMMA":	"Posterior communicating artery",
        "POSTIBIALA":	"Posterior tibial artery",
        "PROFFEMA":	"Profunda femoris artery",
        "PROFFEMV":	"Profunda femoris vein",
        "PROSTATE":	"Prostate",
        "PULMONARYA":	"Pulmonary artery",
        "PULMONARYV":	"Pulmonary vein",
        "RADIALA":	"Radial artery",
        "RADIUS":	"Radius",
        "RADIUSULNA":	"Radius and ulna",
        "CULDESAC":	"Rectouterine pouch",
        "RECTUM":	"Rectum",
        "RENALA":	"Renal artery",
        "RENALV":	"Renal vein",
        "RETROPERITONEUM":	"Retroperitoneum",
        "RIB":	"Rib",
        "RATRIUM":	"Right atrium",
        "RFEMORALA":	"Right femoral artery",
        "RHEPATICV":	"Right hepatic vein",
        "RHYPOCHONDRIAC":	"Right hypochondriac region",
        "RINGUINAL":	"Right inguinal region",
        "RLQ":	"Right lower quadrant of abdomen",
        "RLUMBAR":	"Right lumbar region",
        "RPORTALV":	"Right portal vein",
        "RPULMONARYA":	"Right pulmonary artery",
        "RUQ":	"Right upper quadrant of abdomen",
        "RVENTRICLE":	"Right ventricle",
        "SIJOINT":	"Sacroiliac joint",
        "SSPINE":	"Sacrum",
        "SFJ":	"Saphenofemoral junction",
        "SAPHENOUSV":	"Saphenous vein",
        "SCALP":	"Scalp",
        "SCAPULA":	"Scapula",
        "SCLERA":	"Sclera",
        "SCROTUM":	"Scrotum",
        "SELLA":	"Sella turcica",
        "SEMVESICLE":	"Seminal vesicle",
        "SESAMOID":	"Sesamoid bones of foot",
        "SHOULDER":	"Shoulder",
        "SIGMOID":	"Sigmoid colon",
        "SKULL":	"Skull",
        "SMALLINTESTINE":	"Small intestine",
        "SPINALCORD":	"Spinal cord",
        "SPINE":	"Spine",
        "SPLEEN":	"Spleen",
        "SPLENICA":	"Splenic artery",
        "SPLENICV":	"Splenic vein",
        "SCJOINT":	"Sternoclavicular joint",
        "STERNUM":	"Sternum",
        "STOMACH":	"Stomach",
        "SUBCLAVIANA":	"Subclavian artery",
        "SUBCLAVIANV":	"Subclavian vein",
        "SUBCOSTAL":	"Subcostal",
        "SUBMANDIBULAR":	"Submandibular gland",
        "SFA":	"Superficial femoral artery",
        "SFV":	"Superficial femoral vein",
        "LSUPPULMONARYV":	"Superior left pulmonary vein",
        "SMA":	"Superior mesenteric artery",
        "RSUPPULMONARYV":	"Superior right pulmonary vein",
        "SUPTHYROIDA":	"Superior thyroid artery",
        "SVC":	"Superior vena cava",
        "SUPRACLAVICULAR":	"Supraclavicular region of neck",
        "SUPRAPUBIC":	"Suprapubic region",
        "TMJ":	"Temporomandibular joint",
        "TESTIS":	"Testis",
        "THALAMUS":	"Thalamus",
        "THIGH":	"Thigh",
        "3RDVENTRICLE":	"Third ventricle",
        "THORACICAORTA":	"Thoracic aorta",
        "TSPINE":	"Thoracic spine",
        "TLSPINE":	"Thoraco-lumbar spine",
        "THORAX":	"Thorax",
        "THUMB":	"Thumb",
        "THYMUS":	"Thymus",
        "THYROID":	"Thyroid",
        "TIBIA":	"Tibia",
        "TIBIAFIBULA":	"Tibia and fibula",
        "TOE":	"Toe",
        "TONGUE":	"Tongue",
        "TRACHEA":	"Trachea",
        "TRACHEABRONCHUS":	"Trachea and bronchus",
        "TRANSVERSECOLON":	"Transverse colon",
        "ULNA":	"Ulna",
        "ULNARA":	"Ulnar artery",
        "UMBILICALA":	"Umbilical artery",
        "UMBILICAL":	"Umbilical region",
        "UMBILICALV":	"Umbilical vein",
        "UPPERARM":	"Upper arm",
        "UPPERLIMB":	"Upper limb",
        "UPRURINARYTRACT":	"Upper urinary tract",
        "URETER":	"Ureter",
        "URETHRA":	"Urethra",
        "UTERUS":	"Uterus",
        "VAGINA":	"Vagina",
        "VEIN":	"Vein",
        "VERTEBRALA":	"Vertebral artery",
        "VULVA":	"Vulva",
        "WRIST":	"Wrist joint",
        "ZYGOMA":	"Zygoma",
    }
}

const codeStringMaps: Record<string,CodeStringMap> = {
    PatientSex,
    QualityControlSubject,
    ResponsiblePersonRole,
    SmokingStatus,
    BodyPartExamined
}


export { codeStringMaps }