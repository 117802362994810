import { useMemo, useState } from "react"
import { requestQueue } from "../requestQueue"
import { auth0Axios } from "../../auth/auth0Axios"
import { ExtendedDicomFile } from "../types"
import { isCurrentUploaderInstanceId, clearCurrentUploaderInstanceId } from "../../providers/utils/uploadCancelledState"
import rollbar from "../../rollbar.setup"

/**
 * The amount of times we will try and upload an individual file.
 */
const RETRY_COUNT = 3;
const RETRY_INTERVAL = 1000;
const BACKOFF_FACTOR = 3; // 1s, 3s, 9s => We use an exponential backoff as per google staff recommendations.

/**
 * Given a single file, uploads it to a signed URL.
 */
const uploadOneFileToSignedUrl = (file: File, signedUrl: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.onload = (evt: ProgressEvent<FileReader>): void => {
      const data = evt.target?.result

      if (!data) {
        reject('unparsable data')
      }

      auth0Axios.put(signedUrl,
        data,
        {
          headers: {
            'Content-Type': file.type
          }
        }
      )
        .then(() => resolve())
        .catch((err) => reject(err))
    }

    fileReader.readAsArrayBuffer(file)
  })
}



const uploadOneFileToSignedUrlWithRetry = (file: File, signedUrl: string): Promise<void> => {
  let retryInterval = RETRY_INTERVAL;

  return new Promise(async (resolve, reject) => {
    let attempt = 1;

    const tryUpload = async () => {
      try {
        await uploadOneFileToSignedUrl(file, signedUrl)

        resolve();

        return;
      } catch (error: any) {
        const status = error.response.status;

        if (status !== 503 || attempt === RETRY_COUNT) {
          // In the case of a anything but a 503, just return immediately.
          // Alternatively if we have run out of retries, return.
          reject(error);
          return;
        }

        rollbar.error(error);

        // Otherwise retry
        // Square the attempt to get an exponential backoff of 1,3,9 seconds wait for the call.
        setTimeout(tryUpload, RETRY_INTERVAL);
        attempt += 1;
        retryInterval *= BACKOFF_FACTOR;
      }
    }

    tryUpload();
  });
};

const ATTACHMENTS = 'ATTACHMENTS'


type UploadFileResult = {
  success: boolean,
  resource: string,
  cancelled: boolean,
  file: File | ExtendedDicomFile,
  signedUrl: string
}

const cancel = async (uploaderInstanceId: string): Promise<void> => {
  await auth0Axios.post(`/api/uploader/instance/cancel/${uploaderInstanceId}`)
}

/**
 * Returns an API for uploading files to signedURLs in a queue, and reactive variables to track progress.
 */
const useFileUploadQueue = () => {
  const [totalUploadCount, setTotalUploadCount] = useState<number>(0)
  const [fileGroupUploadCounts, setFileGroupUploadCounts] = useState<Record<string, number>>({})
  const [completedToNotify, setCompletedToNotify] = useState<string[]>([])

  const cancelUpload = useMemo(() => {
    return async (uploaderInstanceUid?: string) => {
      if (uploaderInstanceUid) {
        void cancel(uploaderInstanceUid);
      }
      clearCurrentUploaderInstanceId();
      requestQueue.clear()
      setTotalUploadCount(0)
      setFileGroupUploadCounts({})
      setCompletedToNotify([])
    }
  }, [])

  const uploadFileAndUpdateCount = useMemo(() => {
    return (uploaderInstanceId: string, resource: string, file: File | ExtendedDicomFile, signedUrl: string): Promise<UploadFileResult> => {
      return new Promise((resolve) => {
        requestQueue.add(async () => {
          if (!isCurrentUploaderInstanceId(uploaderInstanceId)) {
            resolve({
              success: false,
              cancelled: true,
              resource,
              file,
              signedUrl
            });
            return;
          }

          try {
            await uploadOneFileToSignedUrlWithRetry(file, signedUrl);
          } catch (error) {
            console.error(error);
            // Put in try/catch
            resolve({
              success: false,
              cancelled: false,
              resource,
              file,
              signedUrl
            });
            return;
          }

          if (!isCurrentUploaderInstanceId(uploaderInstanceId)) {
            resolve({
              success: false,
              cancelled: true,
              resource,
              file,
              signedUrl
            });
            return;
          }

          let fileGroup: string

          if ((file as ExtendedDicomFile).StudyInstanceUID) {
            fileGroup = (file as ExtendedDicomFile).StudyInstanceUID
          } else {
            fileGroup = ATTACHMENTS
          }

          setFileGroupUploadCounts((previousCounts) => {
            const newCounts: Record<string, number> = {}

            Object.keys(previousCounts).forEach(fileGroup => {
              newCounts[fileGroup] = previousCounts[fileGroup]
            })

            if (!newCounts[fileGroup]) {
              newCounts[fileGroup] = 0
            }

            newCounts[fileGroup] += 1

            return newCounts
          })
          setTotalUploadCount((previousCount) => previousCount + 1)
          setCompletedToNotify((list) => [...list, resource])

          resolve({
            success: true,
            cancelled: false,
            resource,
            file,
            signedUrl
          });
        })
      })
    }
  }, [])

  return {
    cancelUpload,
    totalUploadCount,
    fileGroupUploadCounts,
    completedToNotify,
    uploadFileAndUpdateCount
  }
}

export { useFileUploadQueue }
export type { UploadFileResult }