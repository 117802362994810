import React from 'react';
import "./MailTo.css";

const MailTo = ({ supportEmail }: { supportEmail: string }) => {
    return (
        <a className="mail-to-link" href={`mailto:${supportEmail}`}>
            {supportEmail}
        </a>
    );
};

export default MailTo;