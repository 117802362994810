import React, { useEffect, useState } from "react";
import { StudyMetadata, useFileState } from "../../providers/FileStateProvider";
import { searchForPatientByMrn } from "../../lib/api/wms";
import { PatientInfo } from "../../../../sharedTypes/wmsQueryTypes";
import { getDICOMFormattedPatientInfo, DICOMFormatPatientInfo } from "../../lib/patientInformation/getDICOMFormattedPatientInfo";
import PatientInfoInput from "../PatientInfoInput/PatientInfoInput";
import { getDicomMrn } from "../../providers/FileStateProvider";
import UserInputFooterIdentified from "./UserInputFooterIdentified";
import "./UserInput.css"
import AttachmentsWithDropDown from "./AttachmentsWithDropDown";
import LoadingSpinner from "../Shared/LoadingSpinner";
import RewriteUIDs from "./RewriteUIDs";

/**
 * Reducer for setState methods for patient info and deidentified patient info, which returns new state with the key/value pair added or overwritten.
 */
const patientInfoReducer = (oldPatientInfo: Partial<DICOMFormatPatientInfo>, keyword: "PatientID" | "PatientName" | "PatientBirthDate" | "PatientSex", value: string) => {
    const newPatientInfo = { ...oldPatientInfo }

    if (keyword === "PatientSex") {
        if (!["M", "F", "O"].includes(value)) {
            throw new Error('Invalid CS value for PatientSex')
        }
        const codeStringValue = value as "M" | "F" | "O";

        newPatientInfo['PatientSex'] = {
            value: codeStringValue,
            locked: false
        }
    } else {
        newPatientInfo[keyword] = {
            value,
            locked: false
        }
    }

    return newPatientInfo;
}

const patientInformationComplete = (defaultPatientInfo: DICOMFormatPatientInfo | undefined, userDefinedPatientInfo: Partial<DICOMFormatPatientInfo>): boolean => {
    if (!defaultPatientInfo?.PatientBirthDate.value.length && !userDefinedPatientInfo.PatientBirthDate?.value.length) {
        return false;
    }
    if (!defaultPatientInfo?.PatientID.value.length && !userDefinedPatientInfo.PatientID?.value.length) {
        return false;
    }
    if (!defaultPatientInfo?.PatientName.value.length && !userDefinedPatientInfo.PatientName?.value.length) {
        return false;
    }
    if (!defaultPatientInfo?.PatientSex.value.length && !userDefinedPatientInfo.PatientSex?.value.length) {
        return false;
    }

    return true;
}

const verifyUpload = async (
    defaultPatientInfo: DICOMFormatPatientInfo | undefined,
    userDefinedPatientInfo: Partial<DICOMFormatPatientInfo>,
): Promise<boolean> => {
    // Verify all the patient information is filled in.
    if (patientInformationComplete(defaultPatientInfo, userDefinedPatientInfo)) {
        return true;
    }

    return false;
}

type IdentifiedTrialLevelInputProps = {
    siteId: string
}

const IdentifiedTrialLevelInput = ({ siteId }: IdentifiedTrialLevelInputProps) => {
    const {
        studyMetadata,
        uploadInput
    } = useFileState()

    /**
     * Identified Patient
     */
    const [defaultPatientInfo, setDefaultPatientInfo] = useState<DICOMFormatPatientInfo | undefined>(undefined)
    const [userDefinedPatientInfo, setUserDefinedPatientInfo] = useState<Partial<DICOMFormatPatientInfo>>({})

    const [canUpload, setCanUpload] = useState<boolean>(false);
    const [loading, setIsLoading] = useState<boolean>(true);

    /**
     * Verify if all input data is sufficient for upload
     */
    useEffect(() => {
        // Invalidate until we verify.
        setCanUpload(false);

        const verifyUploadAndSetNewStatus = async () => {
            const canUpload = await verifyUpload(

                defaultPatientInfo,
                userDefinedPatientInfo
            );

            setCanUpload(canUpload);
        }

        verifyUploadAndSetNewStatus();
    }, [
        uploadInput,
        defaultPatientInfo,
        userDefinedPatientInfo
    ])

    const setNewPatientValue = (keyword: "PatientID" | "PatientName" | "PatientBirthDate" | "PatientSex", value: string) => {
        setUserDefinedPatientInfo((oldUserDefinedPatientInfo) => {
            return patientInfoReducer(oldUserDefinedPatientInfo, keyword, value);
        })
    }

    const searchForIdentifiedPatient = async (siteId: string): Promise<PatientInfo | undefined> => {
        const mrn = getDicomMrn(studyMetadata)

        const sitePatientForMrn = await searchForPatientByMrn(uploadInput, mrn, siteId)

        if (sitePatientForMrn !== undefined) {
            return sitePatientForMrn.patientInfo;
        }
    }

    const setPatientInfoFromWMSResultOrDicom = (studyMetadata: StudyMetadata[], patientInfoFromWMS?: PatientInfo) => {
        const dicomFormattedPatientInfo = getDICOMFormattedPatientInfo(studyMetadata, patientInfoFromWMS)

        setDefaultPatientInfo(dicomFormattedPatientInfo);

        return dicomFormattedPatientInfo;
    }

    const patientMatching = async (): Promise<void> => {
        const patientInfo = await searchForIdentifiedPatient(siteId);

        setPatientInfoFromWMSResultOrDicom(studyMetadata, patientInfo)
        setIsLoading(false);
    }

    /**
     * Search for the patient based on the MRN on page load.
     */
    useEffect(() => {
        if (uploadInput.guestUploader) {
            // For trial level guest uploaders without deidentification, fill in patient information.
            setPatientInfoFromWMSResultOrDicom(studyMetadata)

            setIsLoading(false);
        } else {
            patientMatching();
        }
    }, [])

    if (loading) {
        return <div><LoadingSpinner /></div>
    }

    /**
     * Include Patient information at the top for identified trial uploads.
     */
    return (
        <div className="user-input-layout">
            <PatientInfoInput
                title={'Patient Information'}
                defaultPatientInfo={defaultPatientInfo}
                setNewValue={setNewPatientValue}
            />
            <AttachmentsWithDropDown />
            <RewriteUIDs />
            <UserInputFooterIdentified
                canUpload={canUpload}
                defaultPatientInfo={defaultPatientInfo}
                userDefinedPatientInfo={userDefinedPatientInfo}
                selectedSiteId={siteId}
            />
        </div>
    );
}


export default IdentifiedTrialLevelInput;