import { allowedDicomDictionary, patientAllowedDicomDictionary } from './allowedDicomDictionary';
import { VR } from '../../../../sharedTypes/deidentifyTypes'

enum InputType {
    FREE_TEXT = "FREE_TEXT",
    NUMBER = "NUMBER",
    SELECT = 'SELECT',
    DATE_PICKER = 'DATE_PICKER',
    PERSON_NAME = 'PERSON_NAME'
}

/**
 * Note this is not a comprehensive list, just what we currently support from our allow list.
 */
const supportedVRs: VR[] = [
    "CS",
    "DA",
    "DT",
    "SH",
    "LO",
    "ST",
    "PN",
    "DS",
    "LT",
    "AS"
]

const inputTypePerVr: Record<string, InputType> = {
    "DA": InputType.DATE_PICKER,
    "DT": InputType.DATE_PICKER,
    "SH": InputType.FREE_TEXT,
    "LO": InputType.FREE_TEXT,
    "ST": InputType.FREE_TEXT,
    "PN": InputType.PERSON_NAME,
    "LT": InputType.FREE_TEXT,
    "AS": InputType.FREE_TEXT,
    "DS": InputType.NUMBER,
    "CS": InputType.SELECT
}

const getInputTypeFromVr = (vr: VR): InputType => {
    if (supportedVRs.includes(vr)) {
        return inputTypePerVr[vr]
    }

    console.error('Unsupported VR, falling back to text')
    return InputType.FREE_TEXT
}


const getVrFromKeyword = (keyword: string): VR => {
    const tagSchema = allowedDicomDictionary[keyword] || patientAllowedDicomDictionary[keyword]
    const { vr } = tagSchema

    return vr as VR
}

export { InputType }
export { getInputTypeFromVr, getVrFromKeyword }