import React, { useCallback } from 'react'
import { useFileState } from '../providers/FileStateProvider'
import { filterFiles } from "../lib/uploadUtils";
import './Attachments.css'
import "./FileUploadInput.css"
import DropZone from './Shared/DropZone';
import './Shared/DropZone.css';


type AttachmentsProps = {
    show: boolean
}

const KB = 1024
const MB = KB * 1024

const formatSize = (sizeInBytes: number): string => {
    if (sizeInBytes > MB) {
        const sizeInMB = sizeInBytes / MB
        return `${sizeInMB.toFixed(1)} MB`
    }

    if (sizeInBytes > KB) {
        const sizeInKB = sizeInBytes / KB

        return `${sizeInKB.toFixed(1)} KB`
    }

    return `${sizeInBytes.toFixed(1)} B`
}

const Attachments = ({ show }: AttachmentsProps) => {
    const onDrop = useCallback((files: File[]) => {
        const filteredFiles = filterFiles(files)
        api.addAttachments(filteredFiles)
    }, [])
    const { api, attachments } = useFileState()

    const selectFilesHandler = (event: any): void => {
        const filteredFiles = filterFiles(event.target.files)

        api.addAttachments(filteredFiles)

        // Set the target to null, this allows the user to upload the same file again if they delete it.
        event.target.value = null
    };

    const attachmentTableRows = attachments.map((attachment, index) => {
        const { name, size } = attachment
        const formattedSize = formatSize(size)

        return (
            <tr className="attachments-table-row" key={index}>
                <td className="attachments-table-filename">{name}</td>
                <td>{formattedSize}</td>
                <td className="attachments-table-delete" onClick={() => api.removeAttachment(name)}>
                    Delete
                </td>
            </tr>
        )
    })

    return <div style={{ display: show ? 'flex' : 'none', flexDirection: "column" }}>
        <div>
            <label htmlFor="attachments-selector" className="custom-file-upload-primary">
                Select Files
            </label>
            <input id="attachments-selector" type="file" onChange={selectFilesHandler} multiple />
        </div>
        <h4>OR</h4>
        <DropZone onDrop={onDrop} />
        {attachmentTableRows.length ?
            <table className="attachments-table">
                <tbody>
                    <tr>
                        <th>Filename</th>
                        <th>Size</th>
                        <th></th>
                    </tr>
                    {attachmentTableRows}
                </tbody>
            </table>
            : null}
    </div>
}

export default Attachments