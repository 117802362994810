import { DeidentifyConfig } from "../../../../sharedTypes/deidentifyTypes";
import { generateDeidentifyConfig } from "../../lib/deidentification/generateDeidentifyConfig";
import { getDicomMrn, StudyMetadata, UploadArgs } from "../FileStateProvider";

const getDeidentifyConfig = (uploadArgs: UploadArgs, studyMetadata: StudyMetadata[]): DeidentifyConfig | undefined => {
    const { uploadInput, deidentification, subjectId } = uploadArgs;
    const { isBlindedTrial, deidentifying } = uploadInput;

    if (deidentification && deidentifying && isBlindedTrial && subjectId) {
        if (deidentification.mandatoryDeidentifyList === undefined) {
            throw new Error('Missing deidentification information for deidentified upload');
        }

        // Note: If we are doing an order level upload, this won't be used.
        const originalMedicalRecordNumber = getDicomMrn(studyMetadata);

        return generateDeidentifyConfig(
            subjectId,
            originalMedicalRecordNumber,
            deidentification.mandatoryDeidentifyList,
            deidentification.userDefinedDeidentifyListPerStudy,
            deidentification.dicomTagOverridesPerStudy,
            deidentification.redactBurnedInPHI,
            studyMetadata
        );
    }

    if (isBlindedTrial && !deidentifying && subjectId) {
        const originalMedicalRecordNumber = getDicomMrn(studyMetadata);

        return {
            originalMedicalRecordNumber,
            subjectId
        };
    }

    if (deidentifying && !isBlindedTrial) {
        throw new Error('Cannot deidentify to a non-blinded trial.');
    }
}

export { getDeidentifyConfig }