import React from 'react';
import { TrialResponse } from '../../../../sharedTypes/wmsQueryTypes';
import StatelessSelectInput from './input/StatelessSelectInput';
import { Option } from './input/StyledReactSelect'
import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { getSupportEmail } from '../../lib/getSupportEmail';
import "./SiteSelector.css"
import MailTo from './MailTo';


type SiteSelectorProps = {
    selectedSiteId?: number;
    trial: TrialResponse;
    onChange: (siteId: number) => void;
    helpDeskEmailAddress?: string;
}

const getOptionsAndTooltipMessage = (
    trial: TrialResponse,
    helpDeskEmailAddress?: string
): { options: Option[], tooltipContent?: React.JSX.Element } => {
    const { trialSites } = trial;

    let options: Option[] = [];
    let inactiveCount = 0;

    trialSites.forEach(ts => {
        const option: Option = { value: String(ts.siteId), label: ts.name };

        if (!ts.active) {
            option.isDisabled = true;
            inactiveCount++;
        }

        options.push(option);
    });

    let tooltipContent: React.JSX.Element | undefined = undefined;

    if (inactiveCount !== 0) {
        const supportEmail = getSupportEmail(helpDeskEmailAddress);
        const tooltipMessage = inactiveCount === options.length
            ? "All sites you can upload to are inactive, please contact "
            : "Some of the sites you can upload to are inactive, please contact ";

        tooltipContent = <div className="site-selector-tooltip-component">
            {tooltipMessage} <MailTo supportEmail={supportEmail} />.
        </div>
    }

    return { options, tooltipContent };
};

function SiteSelector({
    selectedSiteId,
    trial,
    onChange,
    helpDeskEmailAddress
}: SiteSelectorProps) {
    const { options, tooltipContent } = getOptionsAndTooltipMessage(trial, helpDeskEmailAddress)

    let selectedOption: Option | undefined = undefined

    if (selectedSiteId) {
        selectedOption = options.find(op => op.value === String(selectedSiteId))
    }

    const onSelectChange = (value: string) => {
        onChange(Number(value))
    }

    return (
        <div className='site-selector-container'>
            <div className='site-selector-select-container'>
                <h4 className='site-selector-label'>Participating Site</h4>
                <div className='site-selector-input'>
                    <StatelessSelectInput
                        placeholder='Select a participating site'
                        options={options}
                        selectedOption={selectedOption}
                        onChange={onSelectChange}
                    />
                </div>
                {tooltipContent ? (
                    <div>
                        <div className='site-selector-tooltip-icon'>
                            <FontAwesomeIcon icon={faExclamationCircle} data-tooltip-id="site-selector-tooltip" />
                        </div>
                        <Tooltip
                            clickable
                            id="site-selector-tooltip"
                            place="top"
                            style={{ backgroundColor: "var(--background-warning)", color: "var(--text-primary)" }}
                            opacity={1}
                        >
                            {tooltipContent}
                        </Tooltip>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default SiteSelector