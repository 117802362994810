import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Attachments from "../Attachments";
import "./UserInput.css";

const AttachmentsWithDropDown = () => {
    const [showAttachments, setShowAttachments] = useState<boolean>(true)

    return (
        <div>
            <div className="user-input-dropdown">
                <FontAwesomeIcon
                    className="user-input-dropdown-chevron"
                    icon={showAttachments ? faChevronDown : faChevronRight}
                    onClick={() => setShowAttachments(!showAttachments)}
                />
                <h3>Attachments</h3>
            </div>
            <Attachments show={showAttachments} />
        </div>
    )
};

export default AttachmentsWithDropDown;