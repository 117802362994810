import { DeidentificationProtocol, DeidentificationProtocolTag } from "../../../../sharedTypes/deidentifyTypes";
import { UnionDeidentificationConfig, UnionDeidentificationConfigTag } from "./types";
import { allowedDicomDictionary } from "./allowedDicomDictionary";


type GuestProtocolTagValues = {
    defaultValue?: string,
    fixed?: boolean
}

type ProtocolAsMap = Record<string, GuestProtocolTagValues>

const convertToMap = (deidentificationProtocol: DeidentificationProtocol): ProtocolAsMap => {
    const map: ProtocolAsMap = {}

    deidentificationProtocol.forEach(entry => {
        map[entry.tag] = { defaultValue: entry.defaultValue, fixed: entry.fixed }
    })

    return map
}

/**
 * Filters out disallowed tags from the 3rd party provided protocol.
 * The WMS protocol is controlled by us and so we now the tags it provides are allowed.
 */
const filterOutDisallowedTags = (guestDeidentificationProtocol: DeidentificationProtocol): {
    filteredGuestDeidentificationProtocol: DeidentificationProtocol,
    disallowedDeidentifyTags: string[]
} => {
    const filteredGuestDeidentificationProtocol: DeidentificationProtocol = []
    const disallowedDeidentifyTags: string[] = []


    guestDeidentificationProtocol.forEach(entry => {
        const tag = entry.tag

        if (allowedDicomDictionary[tag]) {
            filteredGuestDeidentificationProtocol.push(entry)
        } else {
            disallowedDeidentifyTags.push(tag)
        }
    })

    return {
        filteredGuestDeidentificationProtocol,
        disallowedDeidentifyTags
    };
}


const generateMessageForValue = (guestValue: string): string => {
    return `Value ${guestValue} has been replaced with the mandated value from the Trial Protocol.`
}

const processWMSProtocolTag = (wmsEntry: DeidentificationProtocolTag, guestEntry?: GuestProtocolTagValues): UnionDeidentificationConfigTag => {
    if (!guestEntry) {
        // Just add the WMS entry
        return wmsEntry
    }

    if (wmsEntry.fixed) {
        // If WMS is fixed => Use WMS value and add message.
        const entry: UnionDeidentificationConfigTag = {
            tag: wmsEntry.tag,
            fixed: true,
            defaultValue: wmsEntry.defaultValue,
            keep: wmsEntry.keep
        }

        if (guestEntry.defaultValue) {
            entry.tooltipMessage = generateMessageForValue(guestEntry.defaultValue)
        }

        return entry
    }

    /**
     * If WMS value is not fixed, use the new value if present.
     * It is fixed if the guest protocol dictates this.
     */

    const entry: UnionDeidentificationConfigTag = {
        tag: wmsEntry.tag,
        fixed: false,
        keep: false
    }

    const defaultValue = guestEntry.defaultValue !== undefined ? guestEntry.defaultValue : wmsEntry.defaultValue

    if (defaultValue) {
        entry.defaultValue = defaultValue
    }

    if (guestEntry.fixed) {
        entry.fixed = true
    }

    return entry
}

/**
 * Combine the deidentification protocol from the wms with a potential guest protocol.
 * 
 * - For fixed WMS protocol tags, they take priority over any guest specified tag.
 * - For tags with a conflict that aren't fixed by the WMS, inherit the overidden values from the guest protocol.
 * - For all non conflicting tags, add them both to the list as is.
 */
const combineDeidentifyProtocols = (
    wmsDeidentificationProtocol: DeidentificationProtocol,
    guestDeidentificationProtocol?: DeidentificationProtocol
): {
    unionDeidentificationConfig: UnionDeidentificationConfig,
    disallowedDeidentifyTags: string[]
} => {
    if (!guestDeidentificationProtocol) {
        // Return the wmsDeidentificationProtocol as is.
        return {
            unionDeidentificationConfig: wmsDeidentificationProtocol,
            disallowedDeidentifyTags: []
        }
    }

    const unionDeidentificationConfig: UnionDeidentificationConfig = []

    const {
        filteredGuestDeidentificationProtocol,
        disallowedDeidentifyTags,
    } = filterOutDisallowedTags(guestDeidentificationProtocol)


    // Convert the guest list to a map for fast checking:
    const guestProtocolMap = convertToMap(filteredGuestDeidentificationProtocol)

    // Iterate over the wms, if the guest has it too, reconcile and pop it off the guest input.
    wmsDeidentificationProtocol.forEach(wmsEntry => {
        const tag = wmsEntry.tag
        const guestEntry = guestProtocolMap[tag]

        const newEntry = processWMSProtocolTag(wmsEntry, guestEntry)

        unionDeidentificationConfig.push(newEntry)

        /**
         * Remove the entry from the map if it was present so we don't double count it.
         * Note this no-ops if not present.
         */
        delete guestProtocolMap[tag]
    })


    // iterate over remaining valid guest input and add these.
    Object.keys(guestProtocolMap).forEach(tag => {
        const { defaultValue, fixed } = guestProtocolMap[tag]

        const guestEntry: UnionDeidentificationConfigTag = {
            tag,
            fixed: !!fixed,
            keep: false
        }

        if (defaultValue) {
            guestEntry.defaultValue = defaultValue
        }

        unionDeidentificationConfig.push(guestEntry)
    })

    return {
        unionDeidentificationConfig,
        disallowedDeidentifyTags
    }
}

export { combineDeidentifyProtocols }