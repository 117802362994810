import React from 'react'
import InputComponent from '../Anonymization/input/InputComponent'

type PatientInfoRowProps = {
    displayName: string,
    dicomKeyword: string,
    defaultValue: string,
    fixed: boolean,
    setNewValue: (value: string) => void
}

const PatientInfoRow = ({ displayName, dicomKeyword, defaultValue, fixed, setNewValue }: PatientInfoRowProps) => {
    return (
        <tr>
            <td className='patient-info-table-cell'>{displayName}</td>
            <InputComponent
                dicomKeyword={dicomKeyword}
                setNewDicomTag={setNewValue}
                defaultValue={defaultValue}
                fixed={fixed}
            />
        </tr>
    )
}

export default PatientInfoRow