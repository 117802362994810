import React, { useEffect, useState } from "react";
import { DicomTagOverridesPerStudy, useFileState } from "../../providers/FileStateProvider";
import { UserDefinedDeidentifyListPerStudy } from "../../lib/deidentification/types";
import { searchForDeidentifiedPatient } from "../../lib/api/wms";
import { getDicomMrn } from "../../providers/FileStateProvider";
// @ts-ignore
import debounce from 'lodash.debounce';
import UserInputFooterDeidentified from "./UserInputFooterDeidentified";
import "./UserInput.css"
import AttachmentsWithDropDown from "./AttachmentsWithDropDown";
import AnonymizationWithDropDown from "./AnonymizationWithDropdown";
import LoadingSpinner from "../Shared/LoadingSpinner";
import { verifyDeidentifiedOrBlindedTrialLevelUpload } from "./helpers/verifyDeidentifiedOrBlindedTrialLevelUpload";




type DeidentifiedTrialLevelInputProps = {
    trialId: string,
    siteId: string
}


const DeidentifiedTrialLevelInput = ({ trialId, siteId }: DeidentifiedTrialLevelInputProps) => {
    const {
        dicomTagOverridesPerStudy,
        userDefinedDeidentifyListPerStudy,
        manualRedactBurnedInPHI,
        mandatoryRedactBurnedInPHI,
        mandatoryDeidentifyList,
        studyMetadata,
        uploadInput
    } = useFileState()

    /**
     * Deidentified Patient
     */
    const [defaultDeidentifiedSubjectId, setDefaultDeidentifiedSubjectId] = useState<string | undefined>(uploadInput?.patient?.subjectId);
    const [userDefinedDeidentifiedSubjectId, setUserDefinedDeidentifiedSubjectId] = useState<string | undefined>(undefined);

    const [canUpload, setCanUpload] = useState<boolean>(false);
    const [subjectIdUnavailable, setSubjectIDUnavailable] = useState<boolean>(false);
    const [loading, setIsLoading] = useState<boolean>(true);

    /**
     * Verify if all input data is sufficient for upload
     */
    useEffect(() => {
        // Invalidate until we verify.
        setCanUpload(false);

        const verifyUploadAndSetNewStatus = async () => {
            const originalMedicalRecordNumber = getDicomMrn(studyMetadata);
            const verifyUploadResult = await verifyDeidentifiedOrBlindedTrialLevelUpload(
                siteId,
                trialId,
                originalMedicalRecordNumber,
                defaultDeidentifiedSubjectId,
                userDefinedDeidentifiedSubjectId,
                uploadInput.guestUploader
            );

            setCanUpload(verifyUploadResult.canUpload);
            setSubjectIDUnavailable(verifyUploadResult.subjectIdUnavailable)
        }

        verifyUploadAndSetNewStatus();
    }, [
        uploadInput,
        studyMetadata,
        defaultDeidentifiedSubjectId,
        userDefinedDeidentifiedSubjectId
    ])

    /**
     * Debounce this as we make server calls. Only make the call once after typing has stopped for some time.
     */
    const debounceUpdateDeidentifiedSubjectId = debounce(setUserDefinedDeidentifiedSubjectId, 200);

    const setNewUserDefinedDeidentifiedSubjectId = (subjectId: string) => {
        debounceUpdateDeidentifiedSubjectId(subjectId);
    }

    const patientMatching = async (): Promise<void> => {
        // Check if we have a matching SubjectID from the siteId, trialId and original MRN.
        const originalMedicalRecordNumber = getDicomMrn(studyMetadata)
        const result = await searchForDeidentifiedPatient(siteId, trialId, originalMedicalRecordNumber);
        const subjectId = result ? result.subjectId : undefined;

        setDefaultDeidentifiedSubjectId(subjectId);
        setIsLoading(false);
    }

    /**
     * Search for the patient based on the MRN on page load.
     * Don't do this for blind trial uploads, we will always create a new patient.
     */
    useEffect(() => {
        if (uploadInput.guestUploader) {
            setIsLoading(false);
        } else {
            patientMatching();
        }
    }, [])

    // Note this is the local state, which we then add to the global state after
    // we confirm or go back.
    const [newDicomTagOverridesPerStudy, setNewDicomTagOverridesPerStudy] = useState<DicomTagOverridesPerStudy>(dicomTagOverridesPerStudy)
    const [newUserDefinedDeidentifyListPerStudy, setNewUserDefinedDeidentifyListPerStudy] = useState<UserDefinedDeidentifyListPerStudy>(userDefinedDeidentifyListPerStudy)
    const [removeBurnedInPHI, setRemoveBurnedInPHI] = useState<boolean>(manualRedactBurnedInPHI)

    if (loading) {
        return <div><LoadingSpinner /></div>
    }

    return (
        <div className="user-input-layout">
            <AnonymizationWithDropDown
                subjectIdUnavailable={subjectIdUnavailable}
                isGuestUploader={!!uploadInput.guestUploader}
                defaultDeidentifiedSubjectId={defaultDeidentifiedSubjectId}
                setNewUserDefinedDeidentifiedSubjectId={setNewUserDefinedDeidentifiedSubjectId}
                setNewDicomTagOverridesPerStudy={setNewDicomTagOverridesPerStudy}
                setNewUserDefinedDeidentifyListPerStudy={setNewUserDefinedDeidentifyListPerStudy}
                newUserDefinedDeidentifyListPerStudy={newUserDefinedDeidentifyListPerStudy}
                removeBurnedInPHI={removeBurnedInPHI}
                setRemoveBurnedInPHI={setRemoveBurnedInPHI}
                mandatoryRedactBurnedInPHI={mandatoryRedactBurnedInPHI}
                mandatoryDeidentifyList={mandatoryDeidentifyList}
            />
            <AttachmentsWithDropDown />
            <UserInputFooterDeidentified
                canUpload={canUpload && !subjectIdUnavailable}
                removeBurnedInPHI={removeBurnedInPHI}
                defaultDeidentifiedSubjectId={defaultDeidentifiedSubjectId}
                userDefinedDeidentifiedSubjectId={userDefinedDeidentifiedSubjectId}
                newDicomTagOverridesPerStudy={newDicomTagOverridesPerStudy}
                newUserDefinedDeidentifyListPerStudy={newUserDefinedDeidentifyListPerStudy}
                selectedSiteId={siteId}
            />
        </div>
    );
}


export default DeidentifiedTrialLevelInput;