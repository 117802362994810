import React from 'react';
import { BrowserRouter } from 'react-router-dom';

/* App */
import App from './App';

function BrowserRouterWrapper(props: any)  {
    return (
        <BrowserRouter basename={props.routerBasename}>
            <App/>
        </BrowserRouter>
    );
}

export default BrowserRouterWrapper;
