import { OrderResponse, SitePatientsForPatientResponse, TrialResponse } from "../../../sharedTypes/wmsQueryTypes"

const getNonTemporaryGuesUploaderUploadDestinationMessage = (trialId?: string) => {
    if (trialId) {
        return `Upload Studies to Trial`
    }

    return `Upload Studies`
}

const getTrialString = (trialId: string, trial?: TrialResponse): string => {
    if (trial?.protocolNumber) {
        return trial.protocolNumber
    }

    return trialId;
}


const MONTH_STRING_MAP = {
    "01": 'Jan',
    '02': 'Feb',
    "03": 'Mar',
    '04': 'Apr',
    "05": 'May',
    "06": 'Jun',
    "07": 'Jul',
    "08": 'Aug',
    "09": 'Sep',
    "10": 'Oct',
    "11": 'Nov',
    "12": 'Dec',
}

/**
 * wmsScanDate is formatted like: 2021-08-04T17:00:00+00:00
 * 
 * We format to day-month-year, where month is Jan,Feb...
 */
const formatScanDate = (wmsScanDate: string): string => {
    const dateWithoutTime = wmsScanDate.split('T')[0];
    const [year, month, date] = dateWithoutTime.split('-');

    // @ts-ignore
    const monthString = MONTH_STRING_MAP[month]

    return `${date}-${monthString}-${year}`
}

const getOrderString = (order: OrderResponse): string => {
    const formattedScanDate = formatScanDate(order.scanDate)

    if (order.timePointName) {
        return `${order.timePointName} (${formattedScanDate})`
    }

    return formattedScanDate;
}


const getUploadDestinationMessage = (trialId?: string, trial?: TrialResponse, patient?: SitePatientsForPatientResponse, order?: OrderResponse, isNonTemporaryGuestUploader?: boolean): string => {
    if (isNonTemporaryGuestUploader) {
        return getNonTemporaryGuesUploaderUploadDestinationMessage(trialId)
    }

    if (!trialId) {
        return `Upload studies`
    }

    let uploadDestinationMessage = `Upload Studies to Trial: ${getTrialString(trialId, trial)}`

    if (patient) {
        uploadDestinationMessage += `, Patient: ${patient.name}`
    }

    if (order) {
        uploadDestinationMessage += `, Order: ${getOrderString(order)}`
    }

    return uploadDestinationMessage
}

export { getUploadDestinationMessage }