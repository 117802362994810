import React from 'react';
import './SiteFinder.css';
import YunuHeader from './YunuHeader';


function SiteFinder() {
  // TODO -> We should at some point have a UI to select
  // a site/trial/patient.

  return (
    <div className="App">
      <YunuHeader/>
      <p>Please access the uploader through the Workflow Management System.</p>
    </div>
  );
}

export default SiteFinder;
