
// @ts-ignore We don't have types for dcmjs
import dcmjs from 'dcmjs'
import { DicomDictionary } from './types'
import { getDisplayNameFromDicomKeyword } from './getTagsForDisplay'
import { allowList } from './allowList'


// TODO_JAMES => This is a bit clunky now, maybe clean this up.
const patientAllowList = ['PatientID'];

type Option = {
    value: string,
    label: string
}

const nameMapDicomDictionary = dcmjs.data.DicomMetaDictionary.nameMap as DicomDictionary
const allowedDicomDictionary: DicomDictionary = {}
const patientAllowedDicomDictionary: DicomDictionary = {}

/**
 * Study level tags
 */
allowList.forEach(tag => {
    const dicomTag = nameMapDicomDictionary[tag]

    allowedDicomDictionary[tag] = dicomTag
})

/**
 * Patient level tags
 */
patientAllowList.forEach(tag => {
    const dicomTag = nameMapDicomDictionary[tag]

    patientAllowedDicomDictionary[tag] = dicomTag
})

const ALLOWED_TAG_COUNT = Object.keys(allowedDicomDictionary).length

let allowListOptions: Option[] | undefined = undefined

const getLabelFromDicomKeyword = (dicomKeyword: string): string => {
    const tag = allowedDicomDictionary[dicomKeyword].tag

    return `${tag}: ${getDisplayNameFromDicomKeyword(dicomKeyword)}`
}

/**
 * Lazily create this list as we may never need it.
 */
const getAllowListOptions = (): Option[] => {
    if (allowListOptions !== undefined) {
        return allowListOptions
    }

    allowListOptions = allowList.map(dicomKeyword => {
        return {
            value: dicomKeyword,
            label: getLabelFromDicomKeyword(dicomKeyword)
        }
    })

    return allowListOptions
}


const getFilteredAllowListOptions = (keywordsToOmit: string[]): Option[] => {
    const allowListOptions = getAllowListOptions()

    return allowListOptions.filter(option => !keywordsToOmit.includes(option.value))
}




export { allowedDicomDictionary, patientAllowedDicomDictionary, getFilteredAllowListOptions, ALLOWED_TAG_COUNT }